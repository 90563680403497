import { ConvertStringToOrderType } from '@backend/models';
import { HttpError } from 'react-admin';

import { assetSuperuserModulePrivateRpc, getAuthTokenSafe, deleteOneRpc, deleteManyRpc, getManyFromRpc, getOneFromRpc, FileUtils } from '../utils';
import { BaseProvider } from './BaseProvider';

export class VideoProvider extends BaseProvider {
  public static getList(params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return assetSuperuserModulePrivateRpc.getAllVideoFilesPaginated(
        getAuthTokenSafe(),
        undefined,
        params.sort.field,
        ConvertStringToOrderType(params.sort.order),
        params.filter.id,
        params.pagination.perPage,
        (params.pagination.page - 1) * params.pagination.perPage,
        true
      ).then(data => {
        resolve({
          data: data.items,
          total: data.total
        });
      })
        .catch(error => {
          reject(
            new HttpError(
              error.message || 'Unexpected error',
              error.httpCode,
              error
            )
          );
        })
    });
  }
  
  public static getMany(params: any): Promise<any> {
    return getManyFromRpc(assetSuperuserModulePrivateRpc.getVideoFilesBulk.bind(assetSuperuserModulePrivateRpc), params);
  }

  public static getOne(params: any): Promise<any> {
    return getOneFromRpc(assetSuperuserModulePrivateRpc.getVideoFilesBulk.bind(assetSuperuserModulePrivateRpc), params);
  }

  public static delete(params: any): Promise<any> {
    return deleteOneRpc(assetSuperuserModulePrivateRpc.deleteVideoFile.bind(assetSuperuserModulePrivateRpc), params);
  }

  public static deleteMany(params: any): Promise<any> {
    return deleteManyRpc(assetSuperuserModulePrivateRpc.deleteVideoFile.bind(assetSuperuserModulePrivateRpc), params);
  }

  public static create(params: any) {
    return new Promise((resolve, reject) => {
      if(params.data.file == null){
        reject('File is empty.');
      }

      const arrayBufferPromise: Promise<ArrayBuffer> = FileUtils.FileToArrayBuffer(params.data.file.rawFile);
      arrayBufferPromise.then((arrayBuffer: ArrayBuffer)=>{
        assetSuperuserModulePrivateRpc.uploadVideoFile(
          params.data.file.rawFile.type,
          params.data.file.rawFile.name,
          getAuthTokenSafe(),
          arrayBuffer
        )
        .then(data => {
          resolve({
            data: data,
          });
        })
        .catch(error => {
          reject(
            new HttpError(
              error.message || 'Unexpected error',
              error.httpCode,
              error
            )
          );
        })
      }).catch((reason: any) => {
        reject(`Can't convert file to array buffer.`);
      });
    });
  }

}