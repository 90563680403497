import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AlbumImageCommentUserProfileTag, AlbumImageUserProfileTag, SocialCommentUserProfileTag, SocialPostUserProfileTag, UserProfile } from '@shared/models';
import { FieldProps, TextField, useDataProvider, useGetMany, useRecordContext } from 'react-admin';
import { BuildName, EllipsisText } from '../../utils';
interface OwnProps{
  tagsSource: string,
  ellipsis?: number,
}

type Props = OwnProps & FieldProps;

export const AlbumTextFieldWithTags = ({ source, record, label, tagsSource, ellipsis }: Props) => {
  const [profiles, setProfiles] = useState<Array<UserProfile>>(new Array());
  const dataProvider = useDataProvider();
  const tags: Array<AlbumImageUserProfileTag> | Array<AlbumImageCommentUserProfileTag> | undefined = record != null ? record[tagsSource]: new Array();

  useEffect(() => { 
    if(tags == null){
      return;
    }

    const userProfileIdsToGet: Array<number> = tags.map(v => v.taggedUserProfileId)
      .filter(v => v != null) as Array<number>;

    if(userProfileIdsToGet.length > 0){
      dataProvider.getMany('UserProfiles', { ids: userProfileIdsToGet })
        .then(({ data }) => {
          setProfiles(data as Array<UserProfile>);
        })
        .catch(error => {
          console.log('An error occurred while getting userProfiles for tags')
        })
    }
  }, []);

  if(record == null){
    return <TextField record={{id: 0, value: ''}} source="value"/>;
  }

  let body: string = '';
  if(source != null && record != null){
    body = record[source];
  }

  if(body == null || typeof(body) !== 'string'){
    return <TextField record={{id: 0, value: ''}} source="value"/>;
  }

  if( tags != null && Array.isArray(tags) === true){
    tags.sort((a,b) => a.position - b.position);
    if(source != null){
      let addedLength: number = 0;
      tags.forEach((tag: AlbumImageUserProfileTag | AlbumImageCommentUserProfileTag) => {
        const profile: UserProfile | undefined = profiles.find(v => v.id === tag.taggedUserProfileId)
        let position: number = tag.position + addedLength;
        let text: string = `@${tag.displayName}`;
        if(profile != null){
          text = '@'+BuildName([
            profile.firstName,
            profile.lastName
          ])
        }
        
        addedLength += text.length;
        body = body!.substring(0, position) + text + body!.substring(position);
      });
    } else {
      tags.forEach((tag: AlbumImageUserProfileTag | AlbumImageCommentUserProfileTag) => {
        const profile: UserProfile | undefined = profiles.find(v => v.id === tag.taggedUserProfileId)
        let text: string = `@${tag.displayName}`;
        if(profile != null){
          text = '@'+BuildName([
            profile.firstName,
            profile.lastName
          ])
        }
        
        if(body.length != 0 && text.length != 0){
          body = body + ' ';
        }
        
        body = body + text;
      });
    }
  }

  if(ellipsis != null){
    body = EllipsisText(body, 20)
  }
  return <TextField record={{id: 0, value: body}} source="value"/>;
} 

AlbumTextFieldWithTags.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  addLabel: PropTypes.bool,
  ellipsis: PropTypes.number
};

AlbumTextFieldWithTags.defaultProps  = {
  addLabel: true
}