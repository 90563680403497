import { ConvertStringToOrderType } from '@backend/models';
import { FamilyGroup, Reunion } from '@shared/models';
import { HttpError, UseGetOneHookValue } from 'react-admin';
import { FamilyGroupProvider, ReunionProvider } from '.';

import { reunionActivitySuperuserModulePrivateRpc, getAuthTokenSafe, getManyFromRpc, getOneFromRpc, deleteOneRpc, deleteManyRpc, updateRpc, uploadImageToRecord } from '../utils';
import { BaseProvider } from './BaseProvider';

export class ReunionActivityProvider extends BaseProvider {
  public static getList(params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        return reunionActivitySuperuserModulePrivateRpc.getAllActivitiesPaginated(
          getAuthTokenSafe(),
          undefined,
          params.sort.field,
          ConvertStringToOrderType(params.sort.order),
          params.filter.id,
          params.filter.reunionId,
          params.filter.hostUserId,
          params.pagination.perPage,
          (params.pagination.page - 1) * params.pagination.perPage,
          true
        ).then(data => {
          resolve({
            data: data.items, total: data.total
          });
        })
          .catch(error => {
            reject(
              new HttpError(
                error.message || 'Unexpected error',
                error.httpCode,
                error
              )
            );
          })
      } catch (error) {
        reject(
          new HttpError(
            error.message || 'Unexpected error',
            error.httpCode,
            error
          )
        );
      }
    });
  }

  public static getMany(params: any): Promise<any> {
    return getManyFromRpc(reunionActivitySuperuserModulePrivateRpc.getReunionActivitiesBulk.bind(reunionActivitySuperuserModulePrivateRpc), params);
  }

  public static getOne(params: any): Promise<any> {
    return getOneFromRpc(reunionActivitySuperuserModulePrivateRpc.getReunionActivitiesBulk.bind(reunionActivitySuperuserModulePrivateRpc), params);
  }

  public static async update(params: any): Promise<any> {
    // We need to make the FGO the owner for a new cover image
    const reunionResult: UseGetOneHookValue<Reunion> = await ReunionProvider.getOne({id: params.data.reunionId})
    if(reunionResult.data != null){
      const familyGroupResult: UseGetOneHookValue<FamilyGroup> = await FamilyGroupProvider.getOne({id: reunionResult.data.familyGroupId})
      params.data = await uploadImageToRecord(params.data, 'coverImageId', familyGroupResult.data?.ownerUserId);
    }

    return updateRpc(reunionActivitySuperuserModulePrivateRpc.updateActivity.bind(reunionActivitySuperuserModulePrivateRpc), params);
  }
  
  public static delete(params: any): Promise<any> {
    return deleteOneRpc(reunionActivitySuperuserModulePrivateRpc.deleteActivity.bind(reunionActivitySuperuserModulePrivateRpc), params);
  }

  public static deleteMany(params: any): Promise<any> {
    return deleteManyRpc(reunionActivitySuperuserModulePrivateRpc.deleteActivity.bind(reunionActivitySuperuserModulePrivateRpc), params);
  }
}