import { SuperuserLoginResponse } from '@backend/models';
import moment from 'moment';
import { superuserModulePrivateRpc, getAuthData, getAuthTokenSafe, setAuthData } from '../utils';
import { ResourceAccessTokenUtilInstance } from '../utils/ResourceAccessTokenUtil';

export const authProvider = {
  login: ({ username, password }: { username: string, password: string }) => {
    return superuserModulePrivateRpc.login({ email: username.trim().toLowerCase(), password: password })
      .then((data: SuperuserLoginResponse) => {
        setAuthData(data);
        ResourceAccessTokenUtilInstance.StartRefreshingResourceAccessToken();
      });
  },
  logout: () => {
    const token: string = getAuthTokenSafe();
    localStorage.removeItem('auth_data');
    superuserModulePrivateRpc.logout({ superuserAccessToken: token })
      .catch(error => {
        if (error.httpCode !== 401) {
          console.log(`Unexpected error on logout ${error}`);
        }
      })
    return Promise.resolve();
  },
  checkError: (error: any) => {
    if (error.status === 401) {
      return Promise.reject();
    }

    return Promise.resolve();
  },
  checkAuth: () => {
    const authData: SuperuserLoginResponse | undefined = getAuthData();
    if (authData == null) {
      return Promise.reject();
    }

    const isExpired: boolean = moment.utc(authData.superuserCredentials.superuserAccessToken.expirationTimestamp).isSameOrBefore(moment.utc())

    if (isExpired) {
      return Promise.reject();
    }

    ResourceAccessTokenUtilInstance.StartRefreshingResourceAccessToken();
    return Promise.resolve();
  },
  getPermissions: () => {
    return Promise.resolve('admin')
  }
};