import React from 'react';
import {
  Filter,
  NumberInput,
  Datagrid,
  List,
  TextField,
  ReferenceField,
  ChipField,
} from 'react-admin';

import { CustomPagination } from './elements';

export const ReunionFundFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='Id' source='id' />
    <NumberInput label='Reunion id' source='reunionId' />
  </Filter>
);

export const ReunionFundList = (props: any) => (
  <List
    {...props}
    pagination={<CustomPagination />}
    title={'Reunion funds'}
    bulkActionButtons={false}
    filters={<ReunionFundFilter />}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <ReferenceField source='reunionId' reference='Reunions' link='show'>
        <ChipField source='id' />
      </ReferenceField>
      <TextField source='amountReceived' />
      <TextField source='amountPaid' />
      <TextField source='amountPending' />
      <TextField source='amountFailed' />
    </Datagrid>
  </List>
);
