import { ConvertStringToOrderType } from '@backend/models';
import { HttpError } from 'react-admin';

import { socialSuperuserModulePrivateRpc, getAuthTokenSafe, getManyFromRpc, getOneFromRpc, deleteOneRpc, deleteManyRpc } from '../utils';
import { BaseProvider } from './BaseProvider';

export class SocialPostProvider extends BaseProvider {
  public static getList(params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return socialSuperuserModulePrivateRpc.getAllSocialPostsPaginated(
        getAuthTokenSafe(),
        undefined,
        params.sort.field,
        ConvertStringToOrderType(params.sort.order), 
        params.filter.id,
        params.filter.familyGroupId,
        params.filter.ownerUserProfileId,
        params.pagination.perPage,
        (params.pagination.page - 1) * params.pagination.perPage,
        true
      ).then(data => {
        resolve({
          data: data.items, total: data.total
        });
      })
        .catch(error => {
          reject(
            new HttpError(
              error.message || 'Unexpected error',
              error.httpCode,
              error
            )
          );
        })
    });
  }

  public static getMany(params: any): Promise<any> {
    return getManyFromRpc(socialSuperuserModulePrivateRpc.getSocialPostsBulk.bind(socialSuperuserModulePrivateRpc), params);
  }

  public static getOne(params: any): Promise<any> {
    return getOneFromRpc(socialSuperuserModulePrivateRpc.getSocialPostsBulk.bind(socialSuperuserModulePrivateRpc), params);
  }
   
  public static delete(params: any): Promise<any> {
    return deleteOneRpc(socialSuperuserModulePrivateRpc.deleteSocialPost.bind(socialSuperuserModulePrivateRpc), params);
  }

  public static deleteMany(params: any): Promise<any> {
    return deleteManyRpc(socialSuperuserModulePrivateRpc.deleteSocialPost.bind(socialSuperuserModulePrivateRpc), params);
  }
}