import { ConvertStringToOrderType } from '@backend/models';
import { HttpError } from 'react-admin';

import { familyGroupSuperuserModulePrivateRpc, deleteManyRpc, deleteOneRpc, getAuthTokenSafe } from '../utils';
import { BaseProvider } from './BaseProvider';


export class FamilyGroupManagersProvider extends BaseProvider {
  public static getList(params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      familyGroupSuperuserModulePrivateRpc.getFamilyGroupManagersPaginated(
        getAuthTokenSafe(),
        undefined,
        params.sort.field,
        ConvertStringToOrderType(params.sort.order),
        params.filter.familyGroupId,
        params.filter.userProfileId,
        params.filter.userId,
        params.filter.firstName,
        params.filter.lastName,
        params.pagination.perPage,
        (params.pagination.page - 1) * params.pagination.perPage,
      ).then(data => {
        resolve({
          data: data.items, total: data.total
        });
      })
        .catch(error => {
          reject(
            new HttpError(
              error.message || 'Unexpected error',
              error.httpCode,
              error
            )
          );
        })
    });
  }

  public static deleteMany(params: any): Promise<any> {
    return deleteManyRpc(familyGroupSuperuserModulePrivateRpc.deleteFamilyGroupManager.bind(familyGroupSuperuserModulePrivateRpc), params);
  }

  public static delete(params: any): Promise<any> {
    return deleteOneRpc(familyGroupSuperuserModulePrivateRpc.deleteFamilyGroupManager.bind(familyGroupSuperuserModulePrivateRpc), params);
  }
}