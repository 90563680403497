import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { TextField } from 'react-admin';

export const SimpleDateField = ({ source, record = {}, label, format = 'MM.DD.YYYY HH:mm:ss' }: any) => {
  let value:string = '';
  if(record[source] != null){
    const dateMoment: moment.Moment = moment(record[source]);
    value = dateMoment.isValid()? dateMoment.format(format): '';
  }
  return <TextField record={{id:0, date: value}} source="date"/>;
} 

SimpleDateField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  format: PropTypes.string,
  addLabel: PropTypes.bool
};

SimpleDateField.defaultProps  = {
  addLabel: true
}