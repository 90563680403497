import React from 'react';
import {
  Filter,
  NumberInput,
  TextInput,
  Datagrid,
  List,
  TextField,
  ReferenceField,
  ChipField,
} from 'react-admin';

import { CustomPagination } from './elements';

export const StripeAccountFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='Id' source='id' />
    <NumberInput label='User id' source='userId' />
    <TextInput label='Status' source='status' />
  </Filter>
);

export const StripeAccountList = (props: any) => (
  <List
    {...props}
    pagination={<CustomPagination />}
    title={'Stripe accounts'}
    bulkActionButtons={false}
    filters={<StripeAccountFilter />}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <ReferenceField source='userId' reference='Users' link='show'>
        <ChipField source='id' />
      </ReferenceField>
      <TextField source='accountId' />
      <TextField source='status' />
    </Datagrid>
  </List>
);
