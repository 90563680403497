import React from 'react';
import {
  ArrayField,
  BooleanField,
  ChipField,
  Datagrid,
  List,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  Filter,
  NumberInput,
  TextField,
} from 'react-admin';

import { OnlyDeleteShowActions } from './BasicShowActions';
import { CustomPagination, SimpleDateField, SocialTextFieldWithTags } from './';

export const SocialPostCommentFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='Id' source='id' />
    <NumberInput label='Post id' source='postId' />
    <NumberInput label='Family group id' source='familyGroupId' />
    <NumberInput label='Owner user profile id' source='ownerUserProfileId' />
  </Filter>
);

export const SocialPostCommentList = (props: any) => (
  <List
    {...props}
    pagination={<CustomPagination />}
    title={'Social post comments'}
    filters={<SocialPostCommentFilter />}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <ReferenceField
        source='ownerUserProfileId.id'
        reference='UserProfiles'
        link='show'
        label={'Owner user profile'}
      >
        <ChipField source='id' />
      </ReferenceField>
      <ReferenceField source='postId' reference='SocialPosts'>
        <ChipField source='id' />
      </ReferenceField>
      <SocialTextFieldWithTags
        source={'body'}
        tagsSource={'socialUserProfileTags'}
        ellipsis={20}
      />
      <SimpleDateField source='created' />
      <BooleanField source='active' />
      <TextField source='commentReactions' />
    </Datagrid>
  </List>
);

export const SocialPostCommentShow = (props: any) => (
  <Show {...props} actions={<OnlyDeleteShowActions />}>
    <TabbedShowLayout>
      <Tab label='Basic info'>
        <TextField source='id' />
        <ReferenceField
          source='ownerUserProfileId.id'
          reference='UserProfiles'
          link='show'
          label={'Owner user profile'}
        >
          <ChipField source='id' />
        </ReferenceField>
        <ReferenceField source='postId' reference='SocialPosts' link='show'>
          <ChipField source='id' />
        </ReferenceField>
        <SocialTextFieldWithTags
          source={'body'}
          tagsSource={'socialUserProfileTags'}
        />
        <SimpleDateField source='created' />
        <BooleanField source='active' />
      </Tab>
      <Tab label='Tagged user profiles'>
        <ArrayField source='socialUserProfileTags'>
          <Datagrid>
            <ReferenceField
              source='taggedUserProfile.id'
              reference='UserProfiles'
              link='show'
            >
              <ChipField source='id' />
            </ReferenceField>
            <TextField source='position' />
            <TextField source='displayName' />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label='Reactions'>
        <ArrayField source='commentReactions'>
          <Datagrid rowClick='show'>
            <TextField source='id' />
            <ReferenceField
              source='ownerUserProfileId.id'
              reference='UserProfiles'
              link='show'
              label={'Owner user profile'}
            >
              <ChipField source='id' />
            </ReferenceField>
            <TextField source='reactionType' />
            <SimpleDateField source='created' />
            <BooleanField source='active' />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
