import { ConvertStringToOrderType } from '@backend/models';
import { HttpError } from 'react-admin';

import {
  userSuperuserModulePrivateRpc,
  getAuthTokenSafe,
  getManyFromRpc,
  getOneFromRpc,
  deleteOneRpc,
  deleteManyRpc,
  updateRpc,
  userResetPasswordSuperuserModulePrivateRpc,
} from '../utils';
import { BaseProvider } from './BaseProvider';

export class UserProvider extends BaseProvider {
  public static action(params: any): Promise<any> {
    if (params.action === 'resetPasswordsBulk') {
      return this.resetPasswordsBulk(params);
    }
    return Promise.reject(
      new HttpError('Not implemented', 501, 'Not implemented'),
    );
  }

  public static resetPasswordsBulk(params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return userResetPasswordSuperuserModulePrivateRpc
        .sendResetPasswordsBulk({ ids: params.ids }, getAuthTokenSafe())
        .then((data) => {
          resolve({
            data: data,
          });
        })
        .catch((error) => {
          reject(
            new HttpError(
              error.message || 'Unexpected error',
              error.httpCode,
              error,
            ),
          );
        });
    });
  }

  public static getList(params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      userSuperuserModulePrivateRpc
        .getAllUsersPaginated(
          getAuthTokenSafe(),
          undefined,
          params.filter.email,
          params.filter.firstName,
          params.filter.id,
          params.filter.lastName,
          params.filter.phone,
          params.sort.field,
          ConvertStringToOrderType(params.sort.order),
          params.pagination.perPage,
          (params.pagination.page - 1) * params.pagination.perPage,
          true,
        )
        .then((data) => {
          resolve({
            data: data.items,
            total: data.total,
          });
        })
        .catch((error) => {
          reject(
            new HttpError(
              error.message || 'Unexpected error',
              error.httpCode,
              error,
            ),
          );
        });
    });
  }

  public static getMany(params: any): Promise<any> {
    return getManyFromRpc(
      userSuperuserModulePrivateRpc.getRedactedUsersBulk.bind(
        userSuperuserModulePrivateRpc,
      ),
      params,
    );
  }

  public static getOne(params: any): Promise<any> {
    return getOneFromRpc(
      userSuperuserModulePrivateRpc.getRedactedUsersBulk.bind(
        userSuperuserModulePrivateRpc,
      ),
      params,
    );
  }

  public static delete(params: any): Promise<any> {
    return deleteOneRpc(
      userSuperuserModulePrivateRpc.deleteUser.bind(
        userSuperuserModulePrivateRpc,
      ),
      params,
    );
  }

  public static deleteMany(params: any): Promise<any> {
    return deleteManyRpc(
      userSuperuserModulePrivateRpc.deleteUser.bind(
        userSuperuserModulePrivateRpc,
      ),
      params,
    );
  }

  public static update(params: any): Promise<any> {
    return updateRpc(
      userSuperuserModulePrivateRpc.updateUser.bind(
        userSuperuserModulePrivateRpc,
      ),
      params,
    );
  }
}
