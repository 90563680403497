export const EllipsisText = function (text: string, maxLength: number):string {
  if (text.length <= maxLength) {
    return text;
  }
  const croppedText = text.slice(0, maxLength - 3) + '...';
  return croppedText;
}

export const BuildName = function(namesList: Array<string | null | undefined>, placeholders: Array<string>=[]) {
  return namesList.filter(item => item != null && item.length > 0 && !placeholders.includes(item)).join(' ').replace(/  +/g, ' ');
}