import React from 'react';
import {
  Filter,
  NumberInput,
  TextInput,
  Datagrid,
  NumberField,
  List,
  TextField,
  ReferenceField,
  ChipField,
} from 'react-admin';

import { CustomPagination, SimpleDateField } from './';

export const FamilyGroupPlanBillFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='Id' source='id' />
    <NumberInput label='User id' source='userId' />
    <NumberInput label='Payment intent id' source='paymentIntentId' />
    <NumberInput label='Family group id' source='familyGroupId' />
    <TextInput label='Status' source='status' />
  </Filter>
);

export const FamilyGroupPlanBillList = (props: any) => (
  <List
    {...props}
    pagination={<CustomPagination />}
    title={'Family group plan bills'}
    bulkActionButtons={false}
    filters={<FamilyGroupPlanBillFilter />}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <ReferenceField source='userId' reference='Users' link='show'>
        <ChipField source='id' />
      </ReferenceField>
      <ReferenceField
        source='stripePaymentIntentId'
        reference='StripePaymentIntents'
        link='show'
      >
        <ChipField source='id' />
      </ReferenceField>
      <NumberField source='amount' />
      <SimpleDateField source='payByTimestamp' />
      <SimpleDateField source='paidTimestamp' />
      <TextField source='billStatus' />
      <TextField source='description' />
      <ReferenceField source='familyGroupId' reference='FamilyGroups'>
        <ChipField source='id' />
      </ReferenceField>
    </Datagrid>
  </List>
);
