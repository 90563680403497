// LoginPage.js
import React from 'react';
import { Login, LoginForm } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { FCThemeColors, BuildDataUrlFromSvgString, BuildFCLongLogoSvgString } from '@frontend/theme';
const logoDataUrl: string = BuildDataUrlFromSvgString(BuildFCLongLogoSvgString(FCThemeColors.primary[500]));

const styles = ({
  main: { background: `radial-gradient(circle at 50% 14em, ${FCThemeColors.primary[300]} 0%, ${FCThemeColors.primary[500]} 60%, ${FCThemeColors.primary[900]} 100%)` },
  avatar: {
    background: `url("${logoDataUrl}")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    height: 81,
  },
  icon: { display: 'none' },
});

const NotStyledCustomLoginPage = (props: any) => (
  <Login
    loginform={<LoginForm />}
    {...props}
  />
);

export const LoginPage = withStyles(styles)(NotStyledCustomLoginPage)
