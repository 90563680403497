import React from 'react';

import { DeleteButton, EditButton, TopToolbar } from 'react-admin';

export const BasicShowActions = ({ basePath, data, resource }: any) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
    <DeleteButton basePath={basePath} record={data} resource={resource} />
  </TopToolbar>
);

export const OnlyEditShowActions = ({ basePath, data, resource }: any) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const OnlyDeleteShowActions = ({ basePath, data, resource }: any) => (
  <TopToolbar>
    <DeleteButton basePath={basePath} record={data} resource={resource} />
  </TopToolbar>
);