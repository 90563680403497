import { AlbumSuperuserModulePrivateRpc, AssetSuperuserModulePrivateRpc, FamilyGroupSuperuserModulePrivateRpc, NotificationSuperuserModulePrivateRpc, PaymentSuperuserModulePrivateRpc, ReunionActivitySuperuserModulePrivateRpc, ReunionBudgetSuperuserModulePrivateRpc, ReunionInviteSuperuserModulePrivateRpc, ReunionReportSuperuserModulePrivateRpc, ReunionRsvpResponseSuperuserModulePrivateRpc, ReunionSuperuserModulePrivateRpc, SocialSuperuserModulePrivateRpc, SuperuserModulePrivateRpc, UserDeviceSuperuserModulePrivateRpc, UserProfileSuperuserModulePrivateRpc, UserResetPasswordSuperuserModulePrivateRpc, UserSuperuserModulePrivateRpc } from "@backend/api_client";
import { BASE_URL } from '.';

export const paymentSuperuserModulePrivateRpc: PaymentSuperuserModulePrivateRpc = new PaymentSuperuserModulePrivateRpc(BASE_URL);
export const superuserModulePrivateRpc: SuperuserModulePrivateRpc = new SuperuserModulePrivateRpc(BASE_URL);
export const notificationSuperuserModulePrivateRpc: NotificationSuperuserModulePrivateRpc = new NotificationSuperuserModulePrivateRpc(BASE_URL);
export const assetSuperuserModulePrivateRpc: AssetSuperuserModulePrivateRpc = new AssetSuperuserModulePrivateRpc(BASE_URL);
export const familyGroupSuperuserModulePrivateRpc: FamilyGroupSuperuserModulePrivateRpc = new FamilyGroupSuperuserModulePrivateRpc(BASE_URL);
export const reunionActivitySuperuserModulePrivateRpc: ReunionActivitySuperuserModulePrivateRpc = new ReunionActivitySuperuserModulePrivateRpc(BASE_URL);
export const reunionBudgetSuperuserModulePrivateRpc: ReunionBudgetSuperuserModulePrivateRpc = new ReunionBudgetSuperuserModulePrivateRpc(BASE_URL);
export const reunionSuperuserModulePrivateRpc: ReunionSuperuserModulePrivateRpc = new ReunionSuperuserModulePrivateRpc(BASE_URL);
export const reunionRsvpResponseSuperuserModulePrivateRpc: ReunionRsvpResponseSuperuserModulePrivateRpc = new ReunionRsvpResponseSuperuserModulePrivateRpc(BASE_URL);
export const socialSuperuserModulePrivateRpc: SocialSuperuserModulePrivateRpc = new SocialSuperuserModulePrivateRpc(BASE_URL);
export const userDeviceSuperuserModulePrivateRpc: UserDeviceSuperuserModulePrivateRpc = new UserDeviceSuperuserModulePrivateRpc(BASE_URL);
export const userProfileSuperuserModulePrivateRpc: UserProfileSuperuserModulePrivateRpc = new UserProfileSuperuserModulePrivateRpc(BASE_URL);
export const userSuperuserModulePrivateRpc: UserSuperuserModulePrivateRpc = new UserSuperuserModulePrivateRpc(BASE_URL);
export const albumSuperuserModulePrivateRpc: AlbumSuperuserModulePrivateRpc = new AlbumSuperuserModulePrivateRpc(BASE_URL);
export const reunionInviteSuperuserModulePrivateRpc: ReunionInviteSuperuserModulePrivateRpc = new ReunionInviteSuperuserModulePrivateRpc(BASE_URL);
export const userResetPasswordSuperuserModulePrivateRpc: UserResetPasswordSuperuserModulePrivateRpc = new UserResetPasswordSuperuserModulePrivateRpc(BASE_URL);
export const reunionReportSuperuserModulePrivateRpc: ReunionReportSuperuserModulePrivateRpc = new ReunionReportSuperuserModulePrivateRpc(BASE_URL);
