import ProfileIcon from '@material-ui/icons/AccountCircle';
import CommentIcon from '@material-ui/icons/Comment';
import ReunionIcon from '@material-ui/icons/Event';
import ReunionActivityIcon from '@material-ui/icons/EventNote';
import PostIcon from '@material-ui/icons/Forum';
import NotificationIcon from '@material-ui/icons/Notifications';
import FamilyGroupIcon from '@material-ui/icons/People';
import FamilyGroupManagerIcon from '@material-ui/icons/Build';
import UserIcon from '@material-ui/icons/Person';
import StarIcon from '@material-ui/icons/Star';
import ReunionRsvpResponseIcon from '@material-ui/icons/Receipt';
import SaveTheDateIcon from '@material-ui/icons/Save';
import UserDeviceIcon from '@material-ui/icons/Devices';
import ReunionBudgetIcon from '@material-ui/icons/AttachMoney';
import ReunionBudgetItemIcon from '@material-ui/icons/Money';
import ReunionInviteIcon from '@material-ui/icons/AlternateEmail';
import WalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AudioIcon from '@material-ui/icons/Audiotrack';
import ImageIcon from '@material-ui/icons/Image';
import VideoIcon from '@material-ui/icons/Movie';
import DocumentIcon from '@material-ui/icons/InsertDriveFile';
import CollectionsIcon from '@material-ui/icons/Collections';
import HelpIcon from '@material-ui/icons/Help';

import React from 'react';
import { Admin, ListGuesser, Resource } from 'react-admin';

import {
  FamilyGroupEdit,
  FamilyGroupList,
  FamilyGroupShow,
  LoginPage,
  ReunionActivityEdit,
  ReunionActivityList,
  ReunionActivityShow,
  ReunionEdit,
  ReunionList,
  ReunionRsvpResponseList,
  ReunionRsvpResponseShow,
  ReunionShow,
  SocialPostList,
  SocialPostShow,
  UserEdit,
  UserList,
  UserProfileEdit,
  UserProfileList,
  UserProfileShow,
  UserShow,
  FamilyGroupManagerList,
  AlbumList,
  AlbumShow,
  TutorialVideoList,
  TutorialVideoCreate,
  TutorialVideoEdit
} from './components';
import { SocialPostCommentList, SocialPostCommentShow } from './components/SocialPostComment';
import { authProvider, dataProvider, myTheme } from './providers';
import { SaveTheDateList } from './components/SaveTheDate';
import { NotificationList } from './components/Notification';
import { UserDeviceList } from './components/UserDevice';
import { ReunionBudgetList, ReunionBudgetShow, ReunionBudgetEdit } from './components/ReunionBudget';
import { ReunionBudgetItemList, ReunionBudgetItemShow, ReunionBudgetItemEdit } from './components/ReunionBudgetItem';
import { ReunionInviteList} from './components/ReunionInvite';
import { StripeCustomerList } from './components/StripeCustomer';
import { StripePaymentMethodList } from './components/StripePaymentMethod';
import { BillList } from './components/Bill';
import { StripePaymentIntentList } from './components/StripePaymentIntent';
import { FamilyGroupPlanBillList } from './components/FamilyGroupPlanBill';
import { StripeAccountList } from './components/StripeAccount';
import { ReunionFundList } from './components/ReunionFund';
import { StripePayoutList } from './components/StripePayout';
import { ReunionPayoutList } from './components/ReunionPayout';
import { ReunionRsvpPaymentIntentList } from './components/ReunionRsvpPaymentIntent';
import { ImageList, ImageShow } from './components/Image';
import { DocumentList } from './components/Document';
import { AudioList } from './components/Audio';
import { VideoCreate, VideoList, VideoShow } from './components/Video';
import { StripeBankAccountList } from './components/StripeBankAccount';
//import { SuperuserList, SuperusersCreate, SuperusersEdit, SuperuserShow } from './components/Superusers';
import { AlbumImageList, AlbumImageShow } from './components/AlbumImage';
import { AlbumImageCommentList, AlbumImageCommentShow } from './components/AlbumImageComment';
import { DetailedRsvpReportWithCountsList } from './components/DetailedRsvpReportWithCounts';

const App = () => { 
  return <Admin dataProvider={dataProvider} authProvider={authProvider} theme={myTheme} loginPage={LoginPage}>
    {/* <Resource name="Superusers" list={SuperuserList} show={SuperuserShow} create={SuperusersCreate} edit={SuperusersEdit} options={{ label: 'Superusers' }} icon={StarIcon} /> */}
    <Resource name="Users" list={UserList} show={UserShow} edit={UserEdit} options={{ label: 'Users' }} icon={UserIcon} />
    <Resource name="UserProfiles" show={UserProfileShow} edit={UserProfileEdit} list={UserProfileList} options={{ label: 'User profiles' }} icon={ProfileIcon} />
    <Resource name="FamilyGroups" list={FamilyGroupList} edit={FamilyGroupEdit} show={FamilyGroupShow} options={{ label: 'Family groups' }} icon={FamilyGroupIcon} />
    <Resource name="FamilyGroupManagers" list={FamilyGroupManagerList} options={{ label: 'Family group managers' }} icon={FamilyGroupManagerIcon} />
    <Resource name="Reunions" list={ReunionList} show={ReunionShow} edit={ReunionEdit} options={{ label: 'Reunions' }} icon={ReunionIcon} />
    <Resource name="ReunionActivities" list={ReunionActivityList} show={ReunionActivityShow} edit={ReunionActivityEdit} options={{ label: 'Reunion activities' }} icon={ReunionActivityIcon} />
    <Resource name="ReunionRsvpResponses" list={ReunionRsvpResponseList} show={ReunionRsvpResponseShow} options={{ label: 'Reunion rsvp responses' }} icon={ReunionRsvpResponseIcon} />
    <Resource name="SocialPosts" show={SocialPostShow} list={SocialPostList} options={{ label: 'Social posts' }} icon={PostIcon} />
    <Resource name="SocialPostComments" show={SocialPostCommentShow} list={SocialPostCommentList} options={{ label: 'Social post comments' }} icon={CommentIcon} />
    <Resource name="SaveTheDates" list={SaveTheDateList} options={{ label: 'Save the dates' }} icon={SaveTheDateIcon} />
    <Resource name="Notifications" list={NotificationList} options={{ label: 'Notifications' }} icon={NotificationIcon} />
    <Resource name="UserDevices" list={UserDeviceList} options={{ label: 'User devices' }} icon={UserDeviceIcon} />
    <Resource name="ReunionBudgets" list={ReunionBudgetList} edit={ReunionBudgetEdit} show={ReunionBudgetShow} options={{ label: 'Reunion budgets' }} icon={ReunionBudgetIcon} />
    <Resource name="ReunionBudgetItems" list={ReunionBudgetItemList} edit={ReunionBudgetItemEdit} show={ReunionBudgetItemShow} options={{ label: 'Reunion budget items' }} icon={ReunionBudgetItemIcon} />
    <Resource name="ReunionInvites" list={ReunionInviteList} options={{ label: 'Reunion invite statuses' }} icon={ReunionInviteIcon} />
    <Resource name="Images" list={ImageList} show={ImageShow} options={{ label: 'Images' }} icon={ImageIcon} />
    <Resource name="Documents" list={DocumentList} options={{ label: 'Documents' }} icon={DocumentIcon} />
    <Resource name="Audios" list={AudioList} options={{ label: 'Audio files' }} icon={AudioIcon} />
    <Resource name="Videos" list={VideoList} create={VideoCreate} show={VideoShow} options={{ label: 'Videos' }} icon={VideoIcon} />
    <Resource name="TutorialVideos" list={TutorialVideoList} create={TutorialVideoCreate} edit={TutorialVideoEdit} options={{ label: 'Tutorial Videos' }} icon={HelpIcon} />
    <Resource name="Albums" list={AlbumList} show={AlbumShow} options={{ label: 'Albums' }} icon={CollectionsIcon}/>
    <Resource name="AlbumImages" list={AlbumImageList} show={AlbumImageShow} options={{ label: 'Album Images' }} icon={ImageIcon}/>
    <Resource name="AlbumImageComments" list={AlbumImageCommentList} show={AlbumImageCommentShow} options={{ label: 'Album image comments' }} icon={CommentIcon}/>
    <Resource name="StripeCustomers" list={StripeCustomerList} options={{ label: 'Stripe customers' }} icon={WalletIcon} />
    <Resource name="StripePaymentMethods" list={StripePaymentMethodList} options={{ label: 'Stripe payment methods' }} icon={WalletIcon} />
    <Resource name="StripePaymentIntents" list={StripePaymentIntentList} options={{ label: 'Stripe payment intents' }} icon={WalletIcon} />
    <Resource name="Bills" list={BillList} options={{ label: 'Bills' }} icon={WalletIcon} />
    <Resource name="FamilyGroupPlanBills" list={FamilyGroupPlanBillList} options={{ label: 'Family group plan bills' }} icon={WalletIcon} />
    <Resource name="StripeAccounts" list={StripeAccountList} options={{ label: 'Stripe accounts' }} icon={WalletIcon} />
    <Resource name="StripeBankAccounts" list={StripeBankAccountList} options={{ label: 'Stripe bank accounts' }} icon={WalletIcon} />
    <Resource name="ReunionFunds" list={ReunionFundList} options={{ label: 'Reunion funds' }} icon={WalletIcon} />
    <Resource name="StripePayouts" list={StripePayoutList} options={{ label: 'Stripe payouts' }} icon={WalletIcon} />
    <Resource name="ReunionPayouts" list={ReunionPayoutList} options={{ label: 'Reunion payouts' }} icon={WalletIcon} />
    <Resource name="RsvpPaymentIntents" list={ReunionRsvpPaymentIntentList} options={{ label: 'Rsvp payment intents' }} icon={WalletIcon} />
    <Resource name="DetailedRsvpReportWithCounts" list={DetailedRsvpReportWithCountsList} options={{ label: 'Rsvp report with counts' }} icon={WalletIcon} />
  </Admin>
};

export default App;
