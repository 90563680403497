import React from 'react';
import {
  BooleanField,
  ChipField,
  Datagrid,
  List,
  ReferenceArrayField,
  ReferenceField,
  ArrayField,
  Show,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  Filter,
  NumberInput,
} from 'react-admin';

import { SimpleDateField } from './';

import { OnlyDeleteShowActions } from './BasicShowActions';
import { CustomPagination, SocialTextFieldWithTags } from './elements';

export const SocialPostFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='Id' source='id' />
    <NumberInput label='Family group id' source='familyGroupId' />
    <NumberInput label='Owner user profile id' source='ownerUserProfileId' />
  </Filter>
);

export const SocialPostList = (props: any) => (
  <List
    {...props}
    pagination={<CustomPagination />}
    title={'Social posts'}
    filters={<SocialPostFilter />}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <SocialTextFieldWithTags
        source={'body'}
        tagsSource={'socialUserProfileTags'}
        ellipsis={20}
      />
      <ReferenceField
        source='ownerUserProfileId.id'
        reference='UserProfiles'
        link='show'
        label={'Owner user profile'}
      >
        <ChipField source='id' />
      </ReferenceField>
      <ReferenceField
        source='familyGroupId'
        reference='FamilyGroups'
        link='show'
      >
        <ChipField source='id' />
      </ReferenceField>
      <SimpleDateField source='created' />
      <BooleanField source='active' />
    </Datagrid>
  </List>
);

export const SocialPostShow = (props: any) => (
  <Show {...props} actions={<OnlyDeleteShowActions />}>
    <TabbedShowLayout>
      <Tab label='Basic info'>
        <TextField source='id' />
        <SocialTextFieldWithTags
          source={'body'}
          tagsSource={'socialUserProfileTags'}
        />
        <ReferenceField
          source='ownerUserProfileId.id'
          reference='UserProfiles'
          link='show'
          label={'Owner user profile'}
        >
          <ChipField source='id' />
        </ReferenceField>
        <ReferenceField
          source='familyGroupId'
          reference='FamilyGroups'
          link='show'
        >
          <ChipField source='id' />
        </ReferenceField>
        <SimpleDateField source='created' />
        <BooleanField source='active' />
      </Tab>
      <Tab label='Images'>
        <ReferenceArrayField reference='Images' source='imageIds'>
          <SingleFieldList linkType='show'>
            <ChipField source='id' />
          </SingleFieldList>
        </ReferenceArrayField>
      </Tab>
      <Tab label='Comments'>
        <ArrayField source='commentIds'>
          <SingleFieldList>
            <ReferenceField
              source='id'
              reference='SocialPostComments'
              link='show'
            >
              <ChipField source='id' />
            </ReferenceField>
          </SingleFieldList>
        </ArrayField>
      </Tab>
      <Tab label='Tagged user profiles'>
        <ArrayField source='socialUserProfileTags'>
          <Datagrid>
            <ReferenceField
              source='taggedUserProfile.id'
              reference='UserProfiles'
              link='show'
            >
              <ChipField source='id' />
            </ReferenceField>
            <TextField source='position' />
            <TextField source='displayName' />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label='Reactions'>
        <ArrayField source='postReactions'>
          <Datagrid rowClick='show'>
            <TextField source='id' />
            <ReferenceField
              source='ownerUserProfileId.id'
              reference='UserProfiles'
              link='show'
              label={'Owner user profile'}
            >
              <ChipField source='id' />
            </ReferenceField>
            <TextField source='reactionType' />
            <SimpleDateField source='created' />
            <BooleanField source='active' />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
