import React from 'react';
import { Filter, NumberInput, BooleanField, Datagrid, List, TextField } from 'react-admin';

import { CustomPagination, SimpleDateField } from './';

export const DocumentFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label="Id" source="id" />
  </Filter>
);

export const DocumentList = (props: any) => (
  <List {...props} pagination={<CustomPagination />} title={'Documents'} filters={<DocumentFilter />}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="s3Bucket" />
      <TextField source="s3KeyRaw" />
      <TextField source="contentTypeRaw" />
      <BooleanField source="active" />
      <SimpleDateField source="created" />
      <SimpleDateField source="updated" />
    </Datagrid>
  </List>
);