import { ConvertStringToOrderType } from '@backend/models';
import { HttpError } from 'react-admin';

import { paymentSuperuserModulePrivateRpc, getAuthTokenSafe, getManyFromRpc, getOneFromRpc } from '../utils';
import { BaseProvider } from './BaseProvider';

export class BillProvider extends BaseProvider {
  public static getList(params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return paymentSuperuserModulePrivateRpc.getAllBillsPaginated(
        getAuthTokenSafe(),
        undefined,
        params.sort.field,
        ConvertStringToOrderType(params.sort.order),
        params.filter.id,
        params.filter.userId,
        params.filter.status,
        params.filter.paymentIntentId,
        params.pagination.perPage,
        (params.pagination.page - 1) * params.pagination.perPage
      ).then(data => {
        resolve({
          data: data.items,
          total: data.total
        });
      })
        .catch(error => {
          reject(
            new HttpError(
              error.message || 'Unexpected error',
              error.httpCode,
              error
            )
          );
        })
    });
  }
    
  public static getMany(params: any): Promise<any> {
    return getManyFromRpc(paymentSuperuserModulePrivateRpc.getBillsBulk.bind(paymentSuperuserModulePrivateRpc), params);
  }

  public static getOne(params: any): Promise<any> {
    return getOneFromRpc(paymentSuperuserModulePrivateRpc.getBillsBulk.bind(paymentSuperuserModulePrivateRpc), params);
  }
}