import { HttpError } from 'react-admin';

import { ReunionProvider } from './ReunionProvider';
import { UserProvider } from './UserProvider';
import { FamilyGroupProvider } from './FamilyGroupProvider';
import { ReunionActivityProvider } from './ReunionActivityProvider';
import { UserProfileProvider } from './UserProfileProvider';
import { ReunionRsvpResponseProvider } from './ReunionRsvpResponseProvider';
import { SocialPostProvider } from './SocialPostProvider';
import { SocialPostCommentProvider } from './SocialPostCommentProvider';
//import { SaveTheDateProvider } from './SaveTheDateProvider';
import { NotificationProvider } from './NotificationProvider';
import { UserDeviceProvider } from './UserDeviceProvider';
import { ReunionBudgetProvider } from './ReunionBudgetProvider';
import { ReunionBudgetItemProvider } from './ReunionBudgetItemProvider';
//import { ReunionInviteProvider } from './ReunionInviteProvider';
import { StripeCustomerProvider } from './StripeCustomerProvider';
import { BaseProvider } from './BaseProvider';
import { StripePaymentMethodProvider } from './StripePaymentMethodProvider';
import { StripePaymentIntentProvider } from './StripePaymentIntentProvider';
import { BillProvider } from './BillProvider';
import { FamilyGroupPlanBillProvider } from './FamilyGroupPlanBillProvider';
import { StripeAccountProvider } from './StripeAccountProvider';
import { ReunionFundProvider } from './ReunionFundProvider';
import { StripePayoutProvider } from './StripePayoutProvider';
import { ReunionPayoutProvider } from './ReunionPayoutProvider';
import { RsvpPaymentIntentProvider } from './RsvpPaymentIntentProvider';
import { StripeBankAccountProvider } from './StripeBankAccountProvider';
import { AudioProvider } from './AudioProvider';
import { VideoProvider } from './VideoProvider';
import { ImageProvider } from './ImageProvider';
import { DocumentProvider } from './DocumentProvider';
import { FamilyGroupManagersProvider } from './FamilyGroupManagersProvider';
import { AlbumProvider } from './AlbumProvider';
import { AlbumImageProvider } from './AlbumImageProvider';
import { AlbumImageCommentProvider } from './AlbumImageCommentProvider';
import { getAuthTokenSafe, superuserModulePrivateRpc } from '../utils';
import { ReunionInviteProvider } from './ReunionInviteProvider';
import { SaveTheDateProvider } from './SaveTheDateProvider';
import { TutorialVideoProvider } from './TutorialVideoProvider';
import { DetailedRsvpReportWithCountsProvider } from './DetailedRsvpReportWithCountsProvider';
//import { SuperusersProvider } from './SuperusersProvider';

export class DataProvider extends BaseProvider {
  public providers: Map<string, any> = new Map();

  private setProvider(resource: string, provider: any) {
    this.providers.set(resource.toLowerCase(), provider);
  }

  private getProvider(resource: string) {
    return this.providers.get(resource.toLowerCase());
  }

  constructor() {
    super();
    //this.setProvider('Superusers', SuperusersProvider);
    this.setProvider('Users', UserProvider);
    this.setProvider('Reunions', ReunionProvider);
    this.setProvider('FamilyGroups', FamilyGroupProvider);
    this.setProvider('ReunionActivities', ReunionActivityProvider);
    this.setProvider('UserProfiles', UserProfileProvider);
    this.setProvider('ReunionRsvpResponses', ReunionRsvpResponseProvider);
    this.setProvider('SocialPosts', SocialPostProvider);
    this.setProvider('SocialPostComments', SocialPostCommentProvider);
    this.setProvider('FamilyGroupManagers', FamilyGroupManagersProvider);
    this.setProvider('SaveTheDates', SaveTheDateProvider);
    this.setProvider('Notifications', NotificationProvider);
    this.setProvider('UserDevices', UserDeviceProvider);
    this.setProvider('ReunionBudgets', ReunionBudgetProvider);
    this.setProvider('ReunionInvites', ReunionInviteProvider);
    this.setProvider('ReunionBudgetItems', ReunionBudgetItemProvider);
    this.setProvider('StripeCustomers', StripeCustomerProvider);
    this.setProvider('StripePaymentMethods', StripePaymentMethodProvider);
    this.setProvider('StripePaymentIntents', StripePaymentIntentProvider);
    this.setProvider('Bills', BillProvider);
    this.setProvider('FamilyGroupPlanBills', FamilyGroupPlanBillProvider);
    this.setProvider('StripeAccounts', StripeAccountProvider);
    this.setProvider('ReunionFunds', ReunionFundProvider);
    this.setProvider('StripePayouts', StripePayoutProvider);
    this.setProvider('Albums', AlbumProvider);
    this.setProvider('AlbumImages', AlbumImageProvider);
    this.setProvider('AlbumImageComments', AlbumImageCommentProvider);
    this.setProvider('ReunionPayouts', ReunionPayoutProvider);
    this.setProvider('RsvpPaymentIntents', RsvpPaymentIntentProvider);
    this.setProvider('Audios', AudioProvider);
    this.setProvider('Videos', VideoProvider);
    this.setProvider('Images', ImageProvider);
    this.setProvider('Documents', DocumentProvider);
    this.setProvider('StripeBankAccounts', StripeBankAccountProvider);
    this.setProvider('TutorialVideos', TutorialVideoProvider);
    this.setProvider('DetailedRsvpReportWithCounts', DetailedRsvpReportWithCountsProvider);
  }

  public action(resource: string, params: any) {
    const provider = this.getProvider(resource);

    if (provider != null && provider.action != null) {
      return provider.action(params);
    }

    return Promise.reject(new HttpError(
      'Not implemented',
      501,
      'Not implemented'
    ));
  }

  public getList(resource: string, params: any) {
    const provider = this.getProvider(resource);

    if (provider != null) {
      return provider.getList(params);
    }

    return Promise.reject(new HttpError(
      'Not implemented',
      501,
      'Not implemented'
    ));
  }

  public getOne(resource: string, params: any) {
    const provider = this.getProvider(resource);

    if (provider != null) {
      return provider.getOne(params);
    }

    return Promise.reject(new HttpError(
      'Not implemented',
      501,
      'Not implemented'
    ));
  }

  public getMany(resource: string, params: any) {
    const provider = this.getProvider(resource);

    if (provider != null) {
      return provider.getMany(params);
    }

    return Promise.reject(new HttpError(
      'Not implemented',
      501,
      'Not implemented'
    ));
  }

  public getManyReference(resource: string, params: any) {
    const provider = this.getProvider(resource);

    if (provider != null) {
      return provider.getManyReference(params);
    }

    return Promise.reject(new HttpError(
      'Not implemented',
      501,
      'Not implemented'
    ));
  }

  public create(resource: string, params: any) {
    const provider = this.getProvider(resource);

    if (provider != null) {
      return provider.create(params);
    }

    return Promise.reject(new HttpError(
      'Not implemented',
      501,
      'Not implemented'
    ));
  }

  public update(resource: string, params: any) {
    const provider = this.getProvider(resource);

    if (provider != null) {
      return provider.update(params);
    }

    return Promise.reject(new HttpError(
      'Not implemented',
      501,
      'Not implemented'
    ));
  }

  public updateMany(resource: string, params: any) {
    const provider = this.getProvider(resource);

    if (provider != null) {
      return provider.updateMany(params);
    }

    return Promise.reject(new HttpError(
      'Not implemented',
      501,
      'Not implemented'
    ));
  }

  public delete(resource: string, params: any) {
    const provider = this.getProvider(resource);

    if (provider != null) {
      return provider.delete(params);
    }

    return Promise.reject(new HttpError(
      'Not implemented',
      501,
      'Not implemented'
    ));
  }


  public deleteMany(resource: string, params: any) {
    const provider = this.getProvider(resource);

    if (provider != null) {
      return provider.deleteMany(params);
    }

    return Promise.reject(new HttpError(
      'Not implemented',
      501,
      'Not implemented'
    ));
  }
}

export const dataProvider = new DataProvider();
