import React from 'react';
import PropTypes from 'prop-types';
import { SocialCommentUserProfileTag, SocialPostUserProfileTag } from '@shared/models';
import { FieldProps, TextField, useRecordContext } from 'react-admin';
import { BuildName, EllipsisText } from '../../utils';
interface OwnProps{
  tagsSource: string,
  ellipsis?: number
}

type Props = OwnProps & FieldProps;

export const SocialTextFieldWithTags = ({ source, record, label, tagsSource, ellipsis }: Props) => {
  if(source == null || record == null){
    return <TextField record={{id: 0, value: ''}} source="value"/>;
  }
  let body: string | undefined = record[source];
  const tags: Array<SocialPostUserProfileTag | SocialCommentUserProfileTag> | undefined = record[tagsSource];

  if(body == null || typeof(body) !== 'string'){
    return <TextField record={{id: 0, value: ''}} source="value"/>;
  }

  if( tags != null && Array.isArray(tags) === true){
    tags.sort((a,b) => a.position - b.position);
    let addedLength: number = 0;
    tags.forEach((tag: SocialCommentUserProfileTag | SocialPostUserProfileTag) => {
      let position: number = tag.position + addedLength;
      let text: string = `@${tag.displayName}`;
      if(tag.taggedUserProfile != null){
        text = '@'+BuildName([
          tag.taggedUserProfile.firstName,
          tag.taggedUserProfile.lastName
        ])
      }
      
      addedLength += text.length;
      body = body!.substring(0, position) + text + body!.substring(position);
    });
  }

  if(ellipsis != null){
    body = EllipsisText(body, 20)
  }
  return <TextField record={{id: 0, value: body}} source="value"/>;
} 

SocialTextFieldWithTags.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  addLabel: PropTypes.bool,
  ellipsis: PropTypes.number
};

SocialTextFieldWithTags.defaultProps  = {
  addLabel: true
}