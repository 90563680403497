import { ConvertStringToOrderType } from '@backend/models';
import { HttpError } from 'react-admin';

import { assetSuperuserModulePrivateRpc, getAuthTokenSafe, deleteOneRpc, deleteManyRpc, getManyFromRpc, getOneFromRpc, FileUtils, updateRpc } from '../utils';
import { BaseProvider } from './BaseProvider';

export class TutorialVideoProvider extends BaseProvider {
  public static getList(params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return assetSuperuserModulePrivateRpc.getAllTutorialVideos(
        getAuthTokenSafe(),
      ).then(data => {
        resolve({
          data: data,
          total: data.length
        });
      })
        .catch(error => {
          reject(
            new HttpError(
              error.message || 'Unexpected error',
              error.httpCode,
              error
            )
          );
        })
    });
  }
  
  public static getMany(params: any): Promise<any> {
    return getManyFromRpc(assetSuperuserModulePrivateRpc.getTutorialVideosBulk.bind(assetSuperuserModulePrivateRpc), params);
  }

  public static getOne(params: any): Promise<any> {
    return getOneFromRpc(assetSuperuserModulePrivateRpc.getTutorialVideosBulk.bind(assetSuperuserModulePrivateRpc), params);
  }

  public static delete(params: any): Promise<any> {
    return deleteOneRpc(assetSuperuserModulePrivateRpc.deleteTutorialVideo.bind(assetSuperuserModulePrivateRpc), params);
  }

  public static deleteMany(params: any): Promise<any> {
    return deleteManyRpc(assetSuperuserModulePrivateRpc.deleteTutorialVideo.bind(assetSuperuserModulePrivateRpc), params);
  }

  public static create(params: any) {
    return new Promise((resolve, reject) => {
      assetSuperuserModulePrivateRpc.createTutorialVideo(
        {
          type: params.data.type,
          videoFileId: params.data.videoFileId,
          title: params.data.title,
          description: params.data.description
        },
        getAuthTokenSafe()
      )
      .then(data => {
        resolve({
          data: data,
        });
      })
      .catch(error => {
        reject(
          new HttpError(
            error.message || 'Unexpected error',
            error.httpCode,
            error
          )
        );
      })
    });
  }

  public static async update(params: any): Promise<any> {
    return updateRpc(assetSuperuserModulePrivateRpc.updateTutorialVideo.bind(assetSuperuserModulePrivateRpc), params);
  }
}
