import { createTheme } from '@material-ui/core/styles';
import { FCThemeColors } from "@frontend/theme";

export const myTheme = createTheme({
  palette: {
    common: {
      black: FCThemeColors.primary[500],
      white: FCThemeColors.neutral[0]
    },
    background: {
      paper: FCThemeColors.neutral[0],
    },
    primary: {
      light: FCThemeColors.primary[300],
      main: FCThemeColors.primary[500],
      dark: FCThemeColors.primary[900],
      contrastText: FCThemeColors.neutral[0]
    },
    secondary: {
      light: FCThemeColors.secondary[300],
      main: FCThemeColors.secondary[500],
      dark: FCThemeColors.secondary[900],
      contrastText: FCThemeColors.neutral[0]
    },
    error: {
      main: FCThemeColors.danger[500],
      light: FCThemeColors.danger[300],
      dark: FCThemeColors.danger[900],
      contrastText: FCThemeColors.neutral[0]
    },
    text: {
      primary: FCThemeColors.text[1000],
      secondary: FCThemeColors.text[500],
      disabled: FCThemeColors.text[400],
      hint: FCThemeColors.text[400]
    }
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial',
      'sans-serif',
    ].join(','),
  }
});