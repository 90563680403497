import { SaveTheDateState } from '@shared/models';
import React from 'react';
import {
  ChipField,
  Datagrid,
  EmailField,
  Filter,
  FunctionField,
  List,
  NumberInput,
  Record,
  ReferenceField,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';

import { BuildName, EnumUtils } from '../utils';
import { CustomPagination } from './elements';

export const SaveTheDateFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='User profile id' source='userProfileId' />
    <TextInput label='User profile name' source='searchText' />
    <NumberInput label='Reunion id' source='reunionId' />
    <TextInput label='Email' source='email' />
    <TextInput label='Phone number' source='phone' />
    <SelectInput
      disabled={false}
      source='state'
      choices={EnumUtils.EnumToSelectInputChoices(SaveTheDateState)}
    />
  </Filter>
);

export const SaveTheDateList = (props: any) => (
  <List
    bulkActionButtons={false}
    pagination={<CustomPagination />}
    title={'Reunion invites'}
    filters={<SaveTheDateFilter />}
    {...props}
  >
    <Datagrid rowClick='show'>
      <ReferenceField
        label='User profile id'
        source='userProfile.id'
        reference='UserProfiles'
        link='show'
        sortBy='userProfileId'
      >
        <ChipField source='id' />
      </ReferenceField>
      <ReferenceField
        label='User profile name'
        source='userProfile.id'
        reference='UserProfiles'
        link='show'
        sortBy='userProfileName'
      >
        <FunctionField
          source='id'
          render={(record: Record | undefined) =>
            record != null ? BuildName([record.firstName, record.lastName]) : ''
          }
        />
      </ReferenceField>
      <ReferenceField source='reunionId' reference='Reunions' link='show'>
        <ChipField source='id' />
      </ReferenceField>
      <TextField source='state' />
      <EmailField source='email' />
      <TextField source='phone' />
    </Datagrid>
  </List>
);
