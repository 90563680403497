import React from 'react';
import {
  BooleanInput,
  TextInput,
  NumberInput,
  Filter,
  BooleanField,
  EmailField,
  ChipField,
  Datagrid,
  List,
  ReferenceField,
  TextField,
  FunctionField,
  Record,
} from 'react-admin';

import { BuildName } from '../utils';
import { CustomPagination } from './elements';

export const ReunionInviteFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='User profile id' source='userProfileId' />
    <TextInput label='User profile name' source='searchText' />
    <NumberInput label='Reunion id' source='reunionId' />
    <BooleanInput label='Is invited' source='isInvited' />
    <TextInput label='Email' source='email' />
    <TextInput label='Phone number' source='phone' />
  </Filter>
);

export const ReunionInviteList = (props: any) => (
  <List
    bulkActionButtons={false}
    pagination={<CustomPagination />}
    title={'Reunion invites'}
    filters={<ReunionInviteFilter />}
    {...props}
  >
    <Datagrid rowClick='show'>
      <ReferenceField
        label='User profile id'
        source='userProfile.id'
        reference='UserProfiles'
        link='show'
        sortBy='userProfileId'
      >
        <ChipField source='id' />
      </ReferenceField>
      <ReferenceField
        label='User profile name'
        source='userProfile.id'
        reference='UserProfiles'
        link='show'
        sortBy='userProfileName'
      >
        <FunctionField
          source='id'
          render={(record: Record | undefined) =>
            record != null ? BuildName([record.firstName, record.lastName]) : ''
          }
        />
      </ReferenceField>
      <ReferenceField source='reunionId' reference='Reunions' link='show'>
        <ChipField source='id' />
      </ReferenceField>
      <BooleanField source='isInvited' />
      <EmailField source='email' />
      <TextField source='phone' />
    </Datagrid>
  </List>
);
