import { ConvertStringToOrderType } from '@backend/models';
import { FamilyGroup, UserProfileDateType, UserProfilePastSpouse } from '@shared/models';
import { HttpError, UseGetOneHookValue } from 'react-admin';
import { FamilyGroupProvider } from '.';

import { userProfileSuperuserModulePrivateRpc, getAuthTokenSafe, getManyFromRpc, getOneFromRpc, updateRpc, deleteManyRpc, deleteOneRpc, uploadImageToRecord } from '../utils';
import { BaseProvider } from './BaseProvider';

export class UserProfileProvider extends BaseProvider {
  public static getList(params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      userProfileSuperuserModulePrivateRpc.getAllUserProfilesPaginated(
        getAuthTokenSafe(),
        undefined,
        params.sort.field,
        ConvertStringToOrderType(params.sort.order),
        params.filter.id,
        params.filter.userId,
        params.filter.familyGroupId,
        params.pagination.perPage,
        (params.pagination.page - 1) * params.pagination.perPage,
        true
      ).then(data => {
        resolve({
          data: data.items, total: data.total
        });
      })
        .catch(error => {
          reject(
            new HttpError(
              error.message || 'Unexpected error',
              error.httpCode,
              error
            )
          );
        })
    });
  }

  public static getMany(params: any): Promise<any> {
    return getManyFromRpc(userProfileSuperuserModulePrivateRpc.getUserProfilesBulk.bind(userProfileSuperuserModulePrivateRpc), params);
  }

  public static getOne(params: any): Promise<any> {
    return getOneFromRpc(userProfileSuperuserModulePrivateRpc.getUserProfilesBulk.bind(userProfileSuperuserModulePrivateRpc), params);
  }

  public static delete(params: any): Promise<any> {
    return deleteOneRpc(userProfileSuperuserModulePrivateRpc.removeUserFromProfile.bind(userProfileSuperuserModulePrivateRpc), params);
  }

  public static deleteMany(params: any): Promise<any> {
    return deleteManyRpc(userProfileSuperuserModulePrivateRpc.removeUserFromProfile.bind(userProfileSuperuserModulePrivateRpc), params);
  }

  public static async update(params: any): Promise<any> {
    // If the profile is not owned by a user, then the FGO should own the profile image
    let ownerId: number | undefined = params.data.userId;
    if(ownerId == null){
      const familyGroupResult: UseGetOneHookValue<FamilyGroup> = await FamilyGroupProvider.getOne({id: params.data.familyGroupId})
      ownerId = familyGroupResult.data?.ownerUserId;
    }
    params.data = await uploadImageToRecord(params.data, 'profileImageId', ownerId);

    const normalizedPastSpouses: Array<UserProfilePastSpouse> = params.data.pastSpousesProfiles.map(
      (value: UserProfilePastSpouse)=>{
        return {
          ...value,
          userProfileId: params.data.id,
          marriageDate: value.marriageDate? value.marriageDate: undefined,
          marriageDateType: value.marriageDate? UserProfileDateType.Exact: undefined
        }
      }
    );
    params.data.pastSpousesProfiles = normalizedPastSpouses;

    return updateRpc(userProfileSuperuserModulePrivateRpc.updateUserProfile.bind(userProfileSuperuserModulePrivateRpc), params);
  }
}