import { ReunionTheme, ReunionStatus } from '@shared/models';
import React, { useState } from 'react';
import {
  Button,
  DeleteButton,
  EditButton,
  SelectInput,
  TopToolbar,
  useNotify,
  useMutation,
  useRefresh,
} from 'react-admin';
import {
  ArrayField,
  BooleanField,
  ChipField,
  Datagrid,
  List,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  Edit,
  ReferenceInput,
  TabbedForm,
  FormTab,
  TextInput,
  DateTimeInput,
  BooleanInput,
  SimpleFormIterator,
  ArrayInput,
  Show,
  SingleFieldList,
  NumberInput,
  AutocompleteInput,
  Tab,
  TabbedShowLayout,
  TextField,
  Filter,
} from 'react-admin';
import { useRecordContext } from 'react-admin';
import WalletIcon from '@material-ui/icons/AccountBalanceWallet';

import { EnumUtils } from '../utils';

import {
  AlertDialog,
  CustomPagination,
  SimpleDateField,
  FCImageField,
  FCImageInput,
} from './';

export const ReunionShowActions = ({ basePath, data, resource }: any) => {
  const [mutate] = useMutation();
  const notify = useNotify();
  const refresh = useRefresh();
  const [isChargeAlertOpen, setIsChargeAlertOpen] = useState(false);

  return (
    <TopToolbar>
      {data != null && data.status === ReunionStatus.Published && (
        <Button
          startIcon={<WalletIcon />}
          label='Force Charges'
          basePath={basePath}
          record={data}
          disabled={data.chargeProcessed === true}
          onClick={() => {
            setIsChargeAlertOpen(true);
          }}
        />
      )}
      <EditButton basePath={basePath} record={data} />
      <DeleteButton basePath={basePath} record={data} resource={resource} />
      <AlertDialog
        title={'Confirm'}
        text={
          'Are you sure you want to manually force charges to occur for this reunion? No one else will be able to RSVP and charges will be processed immediately.'
        }
        isOpen={isChargeAlertOpen}
        onClose={() => {
          setIsChargeAlertOpen(false);
        }}
        actions={[
          {
            title: 'Force Charges',
            onClick: () => {
              mutate(
                {
                  type: 'action',
                  resource: 'Reunions',
                  payload: { id: data.id, action: 'charge' },
                },
                {
                  onFailure: (error: any) => {
                    notify(error.message, 'error');
                    refresh();
                  },
                  onSuccess: () => {
                    notify('Reunion has been charged', 'success');
                    refresh();
                  },
                  mutationMode: 'pessimistic',
                },
              );
              setIsChargeAlertOpen(false);
            },
          },
          {
            title: 'Cancel',
            onClick: () => {
              setIsChargeAlertOpen(false);
            },
          },
        ]}
      />
    </TopToolbar>
  );
};

export const ReunionFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='Id' source='id' />
    <NumberInput label='Family group id' source='familyGroupId' />
    <TextInput label='Display Name' source='displayName' />
    <SelectInput
      disabled={false}
      source='status'
      choices={EnumUtils.EnumToSelectInputChoices(ReunionStatus)}
    />
  </Filter>
);

export const ReunionList = (props: any) => (
  <List
    {...props}
    pagination={<CustomPagination />}
    title={'Reunions'}
    filters={<ReunionFilter />}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='status' />
      <TextField source='displayName' />
      <TextField source='urlSuffix' />
      <SimpleDateField source='startTimestamp' />
      <SimpleDateField source='endTimestamp' />
      <BooleanField source='active' />
      <ReferenceField
        source='familyGroupId'
        reference='FamilyGroups'
        link='show'
      >
        <ChipField source='id' />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const ReunionShow = (props: any) => (
  <Show {...props} actions={<ReunionShowActions />}>
    <TabbedShowLayout>
      <Tab label='Basic info'>
        <TextField source='id' />
        <TextField source='status' />
        <TextField source='paymentCategory' />
        <TextField source='displayName' />
        <TextField source='description' />
        <TextField source='urlSuffix' />
        <TextField source='hyperlink' />
        <BooleanField source='active' />
        <BooleanField source='chargeProcessed' />
        <ReferenceField
          source='familyGroupId'
          reference='FamilyGroups'
          link='show'
        >
          <ChipField source='id' />
        </ReferenceField>
        <ReferenceField source='coverImageId' reference='Images' link='show'>
          <FCImageField title='Cover image' source='id' />
        </ReferenceField>
        <TextField source='theme' />
      </Tab>
      <Tab label='Timestamps and location'>
        <SimpleDateField source='startTimestamp' />
        <SimpleDateField source='endTimestamp' />
        <SimpleDateField source='rsvpDeadline' />
        <TextField source='locationTimezone' />
        <TextField source='locationAddress' />
        <NumberField source='lat' />
        <NumberField source='lng' />
        <TextField source='neLat' />
        <TextField source='neLng' />
        <TextField source='swLat' />
        <TextField source='swLng' />
      </Tab>
      <Tab label='Cost'>
        <NumberField source='adultCost' />
        <NumberField source='teenCost' />
        <NumberField source='childCost' />
        <NumberField source='infantCost' />
        <NumberField source='seniorCost' />
      </Tab>
      <Tab label='Addons'>
        <ArrayField source='addons'>
          <Datagrid>
            <TextField source='id' />
            <TextField source='displayName' />
            <TextField source='cost' />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label='Organizers'>
        <ReferenceArrayField reference='Users' source='organizerIds'>
          <SingleFieldList linkType='show'>
            <ChipField source='id' />
          </SingleFieldList>
        </ReferenceArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const ReunionEdit = (props: any) => {
  const record = useRecordContext(props);
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label='Basic info'>
          <TextInput source='id' disabled />
          <TextInput source='status' disabled />
          <TextInput source='paymentCategory' />
          <TextInput source='displayName' />
          <TextInput source='description' />
          <TextInput source='urlSuffix' />
          <TextInput source='hyperlink' />
          <BooleanInput source='active' disabled />
          <TextInput source='familyGroupId' disabled />
          <TextInput source='coverImageId' disabled />
          <SelectInput
            disabled={false}
            source='theme'
            choices={EnumUtils.EnumToSelectInputChoices(ReunionTheme)}
          />
          <FCImageInput source='coverImageId' title='Cover image' />
        </FormTab>
        <FormTab label='Timestamps and location'>
          <DateTimeInput source='startTimestamp' />
          <DateTimeInput source='endTimestamp' />
          <DateTimeInput source='rsvpDeadline' />
          <TextInput source='locationTimezone' />
          <TextInput source='locationAddress' />
          <NumberInput source='lat' />
          <NumberInput source='lng' />
          <NumberInput source='neLat' />
          <NumberInput source='neLng' />
          <NumberInput source='swLat' />
          <NumberInput source='swLng' />
        </FormTab>
        <FormTab label='Cost'>
          <NumberInput source='adultCost' />
          <NumberInput source='teenCost' />
          <NumberInput source='childCost' />
          <NumberInput source='infantCost' />
          <NumberInput source='seniorCost' />
        </FormTab>
        <FormTab label='Organizers'>
          <ArrayInput source='organizerIds'>
            <SimpleFormIterator>
              <ReferenceInput reference='Users'>
                <AutocompleteInput
                  optionText={(value: any) =>
                    value
                      ? `${value.id}: ${value.firstName} ${value.lastName}`
                      : null
                  }
                  optionValue='id'
                />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
