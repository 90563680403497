import { ReunionInviteStatus } from '@shared/models';
import { ConvertStringToOrderType } from '@backend/models';
import { HttpError } from 'react-admin';

import { getAuthTokenSafe, deleteManyRpc, deleteOneRpc, getManyFromRpc, getOneFromRpc, updateRpc, reunionInviteSuperuserModulePrivateRpc } from '../utils';
import { BaseProvider } from './BaseProvider';

export class ReunionInviteProvider extends BaseProvider {
  public static getList(params: any): Promise<any> {
    if(params.sort.field === 'id'){
      params.sort.field = 'email';
      params.sort.order = 'asc'
    }
    return new Promise((resolve, reject) => {
      return reunionInviteSuperuserModulePrivateRpc.getAllReunionInviteStatusesPaginated(
        getAuthTokenSafe(),
        undefined,
        params.sort.field,
        ConvertStringToOrderType(params.sort.order),
        params.filter.reunionId,
        params.filter.userProfileId,
        params.filter.isInvited,
        params.filter.email,
        params.filter.phone,
        params.filter.searchText,
        params.pagination.perPage,
        (params.pagination.page - 1) * params.pagination.perPage
      ).then(data => {
        resolve({
          data: data.items.map((item: ReunionInviteStatus) => {
            return {
              ...item,
              id: `${ item.reunionId}-${item.userProfile.id}`
            }
          }),
          total: data.total
        });
      })
        .catch(error => {
          reject(
            new HttpError(
              error.message || 'Unexpected error',
              error.httpCode,
              error
            )
          );
        })
    });
  }
}