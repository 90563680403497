import { SuperuserResourceAccessToken } from '@backend/models';
import { CircularProgress, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useRecordContext } from 'react-admin';
import styled from 'styled-components';

import { getAuthTokenSafe, superuserModulePrivateRpc } from '../../utils';

const baseUrl = process.env.REACT_APP_API_DOMAIN!;

const StyledVideo = styled.video`
  max-height: 320px;
  max-width: 320px;
`;

const NoVideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 320px;
  min-width: 320px;
  border: 1px solid black;
`;

export const FCVideoField = (props: any) => {
  const { source } = props;
  const record = useRecordContext(props);
  const [resourceAccessToken, setResourceAccessToken] = useState<SuperuserResourceAccessToken | undefined>();
  const [error, setError] = useState<string | undefined>();
  let videoId: number | undefined;
  if(source != null && source.length > 0){
    videoId = record[source];
  } else {
    // Due to react-admin typings bug
    videoId = (record as unknown) as number;
  }

  useEffect(()=>{
    const authToken: string = getAuthTokenSafe();

    if(videoId == null && typeof(videoId) !== 'number'){
      return;
    }
    
    const getResourceAccessToken = ()=>{
      try{
        superuserModulePrivateRpc.createSuperuserResourceAccessToken(authToken, {withCredentials: true})
        .then(setResourceAccessToken)
        .catch((e: any) => {
          setError('Video loading failed, please try later.')
        });
      } catch(e){
        setError('Video loading failed, please try later.')
      }
    }
    getResourceAccessToken();
    const interval: NodeJS.Timeout = setInterval(getResourceAccessToken, 1000*60*45);

    return () => clearInterval(interval);
  }, [videoId]);

  if(resourceAccessToken != null){
    return <StyledVideo controls src={`${baseUrl}/admin/asset/video/one/${videoId}`}></StyledVideo>;
  }
  return <NoVideoWrapper>
    { error != null && 
      <Typography color="error">{error}</Typography>
    }
    { error == null && 
      <CircularProgress />
    }
  </NoVideoWrapper>
}

FCVideoField.defaultProps = {
  addLabel: true,
};