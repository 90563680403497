import { getAuthTokenSafe } from "./AuthUtils";
import { HttpError } from 'react-admin';
import { AssetSuperuserModulePrivateRpc } from "@backend/api_client";
import { FileUtils } from ".";
import { ImageFile } from "@shared/models";

const baseUrl = process.env.REACT_APP_API_DOMAIN!;
const assetSuperuserModulePrivateRpc: AssetSuperuserModulePrivateRpc = new AssetSuperuserModulePrivateRpc(baseUrl);

export const getManyFromRpc = (rpc: any, params: any) => {
  return rpc(
    params.ids.join(','),
    getAuthTokenSafe(),
    undefined
  ).then((data: any) => {
    return {
      data: data
    }
  })
    .catch((error: any) => {
      throw new HttpError(
        error.message || 'Unexpected error',
        error.httpCode
      )
    })
}

export function getOneFromRpc(rpc: any, params: any) {
  return rpc(
    `${params.id}`,
    getAuthTokenSafe(),
    undefined
  ).then((data: any) => {
    return {
      data: (data != null && data.length > 0) ? data[0] : undefined
    }
  })
    .catch((error: any) => {
      throw new HttpError(
        error.message || 'Unexpected error',
        error.httpCode
      )
    })
};

export function deleteOneRpc(rpc: any, params: any) {
  return rpc(
    params.id,
    getAuthTokenSafe()
  ).then(() => {
    return {
      data: params.id
    };
  })
    .catch((error: any) => {
      throw new HttpError(
        error.message || 'Unexpected error',
        error.httpCode
      );
    });
}

export function deleteManyRpc(rpc: any, params: any) {
  const deletePromises: Array<Promise<any>> = [];
  for (let i = 0; i < params.ids.length; i++) {
    const id = params.ids[i];

    deletePromises.push(deleteOneRpc(rpc, { id: id }));
  }
  return Promise.all(deletePromises).then((data: Array<any>) => {
    return {
      data: data.map(item => item.data)
    }
  })
}

export async function uploadImageToRecord(record: any, source: string, ownerId: number | undefined) {
  if(record[source] == null || ownerId == null){
    return record;
  }

  const file: any = record[source].rawFile;
  if(file == null || !(file instanceof File)){
    return record;
  }

  const authToken: string = getAuthTokenSafe()
  const arrayBuffer: ArrayBuffer = await FileUtils.FileToArrayBuffer(file);
  const imageFile: ImageFile = await assetSuperuserModulePrivateRpc.uploadImageFile(
    file.type, 
    ownerId, 
    authToken, 
    arrayBuffer
  );
  const newRecord: any = {
    ...record
  };
  newRecord[source] = imageFile.id;

  return newRecord;
}

export function updateRpc(rpc: any, params: any) {
  return rpc(
    params.id,
    params.data,
    getAuthTokenSafe()
  ).then((data: any) => {
    return { data: data };
  })
    .catch((error: any) => {
      throw new HttpError(
        error.message || 'Unexpected error',
        error.httpCode,
        error
      )
    });
}

export function safeEncodeQueryParam(param: string | undefined): string | undefined {
  if (param == null) {
    return undefined
  }

  return encodeURIComponent(param);
}