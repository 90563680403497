import React from 'react';
import {
  ChipField,
  Edit,
  SelectInput,
  FormTab,
  TabbedForm,
  Filter,
  NumberInput,
  TextInput,
  Datagrid,
  Show,
  List,
  NumberField,
  TabbedShowLayout,
  Tab,
  ReferenceField,
  TextField,
} from 'react-admin';
import { BasicShowActions } from './BasicShowActions';
import { CustomPagination } from './elements';

export const ReunionBudgetItemFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='Id' source='id' />
    <NumberInput label='Activity id' source='activityId' />
    <NumberInput label='Budget id' source='budgetId' />
    <NumberInput label='Expense type id' source='expenseTypeId' />
    <TextInput label='Cost type' source='costType' />
  </Filter>
);

export const ReunionBudgetItemList = (props: any) => (
  <List
    {...props}
    pagination={<CustomPagination />}
    title={'Reunion budget items'}
    filters={<ReunionBudgetItemFilter />}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='title' />
      <TextField source='costType' />
      <TextField source='note' />
      <ReferenceField
        source='activityId'
        reference='ReunionActivities'
        link='show'
      >
        <ChipField source='id' />
      </ReferenceField>
      <ReferenceField source='budgetId' reference='ReunionBudgets' link='show'>
        <ChipField source='id' />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const ReunionBudgetItemShow = (props: any) => (
  <Show {...props} actions={<BasicShowActions />}>
    <TabbedShowLayout>
      <Tab label='Basic info'>
        <TextField source='id' />
        <TextField source='title' />
        <TextField source='costType' />
        <TextField source='note' />
        <NumberField source='expenseTypeId' />
        <ReferenceField
          source='activityId'
          reference='ReunionActivities'
          link='show'
        >
          <ChipField source='id' />
        </ReferenceField>
        <ReferenceField
          source='budgetId'
          reference='ReunionBudgets'
          link='show'
        >
          <ChipField source='id' />
        </ReferenceField>
      </Tab>
      <Tab label='Flat'>
        <NumberField source='estimatePrice' />
        <NumberField source='actualPrice' />
      </Tab>
      <Tab label='Cost Head'>
        <NumberField source='adultEstimatePrice' />
        <NumberField source='seniorEstimatePrice' />
        <NumberField source='childrenEstimatePrice' />
        <NumberField source='adultEstimateAmount' />
        <NumberField source='seniorEstimateAmount' />
        <NumberField source='childrenEstimateAmount' />
        <NumberField source='adultActualPrice' />
        <NumberField source='seniorActualPrice' />
        <NumberField source='childrenActualPrice' />
        <NumberField source='adultActualAmount' />
        <NumberField source='seniorActualAmount' />
        <NumberField source='childrenActualAmount' />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const ReunionBudgetItemEdit = (props: any) => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label='Basic info'>
        <NumberInput source='id' label='Id' disabled />
        <TextInput source='title' label='Title' />
        <SelectInput
          source='costType'
          choices={[
            { id: 'Flat', name: 'Flat' },
            { id: 'CostHead', name: 'CostHead' },
          ]}
        />
        <TextInput source='note' label='Note' />
        <NumberInput source='expenseTypeId' label='Expense type id' />
        <NumberInput source='activityId' label='Activity id' />
        <NumberInput source='budgetId' label='Budget id' disabled />
      </FormTab>
      <FormTab label='Flat'>
        <NumberInput source='estimatePrice' label='Estimate price' />
        <NumberInput source='actualPrice' label='Actual price' />
      </FormTab>
      <FormTab label='Cost head'>
        <NumberInput source='adultEstimatePrice' label='Adult estimate price' />
        <NumberInput
          source='seniorEstimatePrice'
          label='Senior estimate price'
        />
        <NumberInput
          source='childrenEstimatePrice'
          label='Children estimate price'
        />
        <NumberInput
          source='adultEstimateAmount'
          label='Adult estimate amount'
        />
        <NumberInput
          source='seniorEstimateAmount'
          label='Senior estimate amount'
        />
        <NumberInput
          source='childrenEstimateAmount'
          label='Children estimate amount'
        />
        <NumberInput source='adultActualPrice' label='Adult actual price' />
        <NumberInput source='seniorActualPrice' label='Senior actual price' />
        <NumberInput
          source='childrenActualPrice'
          label='Children actual price'
        />
        <NumberInput source='adultActualAmount' label='Adult actual amount' />
        <NumberInput source='seniorActualAmount' label='Senior actual amount' />
        <NumberInput
          source='childrenActualAmount'
          label='Children actual amount'
        />
      </FormTab>
    </TabbedForm>
  </Edit>
);
