import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react';

interface AlertDialogAction{
  title: string,
  onClick: () => void
}

interface Props{
  isOpen?: boolean;
  onClose?: () => void;
  actions?: Array<AlertDialogAction>;
  text?: string;
  title?: string;
}

export class AlertDialog extends React.Component<Props> {
  public render(){
    let {
      isOpen,
      onClose,
      actions,
      title,
      text
    } = this.props;

    if(isOpen == null){
      isOpen = false;
    }
    if(onClose == null){
      onClose = () => {};
    }
    if(actions == null){
      actions = new Array();
    }
    if(title == null){
      title = '';
    }
    if(text == null){
      text = '';
    }

    return (
      <div>
        <Dialog
          open={isOpen}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {text}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {actions.map((action: AlertDialogAction, index: number) => (
              <Button key={index} onClick={action.onClick}>{action.title}</Button>
            ))}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}