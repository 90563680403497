import { UserProfile, UserProfileStatus } from '@shared/models';
import React, { useEffect, useState } from 'react';
import {
  ArrayField,
  ArrayInput,
  BooleanField,
  SelectInput,
  BooleanInput,
  ChipField,
  Datagrid,
  DateInput,
  DateTimeInput,
  Edit,
  EmailField,
  FormTab,
  List,
  ReferenceField,
  Show,
  SimpleFormIterator,
  SingleFieldList,
  Tab,
  TabbedForm,
  TabbedShowLayout,
  TextField,
  NumberInput,
  Filter,
  TextInput,
  useRecordContext,
  ReferenceInput,
  AutocompleteInput,
  useDataProvider,
  DataProvider,
  GetOneResult,
  Record,
} from 'react-admin';
import styled from 'styled-components';

import { EnumUtils } from '../utils';

import { BasicShowActions } from './BasicShowActions';
import {
  CustomPagination,
  FCImageField,
  FCImageInput,
  SimpleDateField,
} from './';

export const UserProfileFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='Id' source='id' />
    <NumberInput label='User id' source='userId' />
    <NumberInput label='Family group id' source='familyGroupId' />
  </Filter>
);

export const UserProfileList = (props: any) => (
  <List
    {...props}
    pagination={<CustomPagination />}
    title={'User profiles'}
    filters={<UserProfileFilter />}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='email' />
      <TextField source='firstName' />
      <TextField source='lastName' />
      <SimpleDateField source='birthDate' />
      <TextField source='deathDate' />
      <TextField source='status' />
      <BooleanField source='active' />
      <BooleanField source='reconciled' />
      <ReferenceField source='userId' reference='Users' link='show'>
        <ChipField source='id' />
      </ReferenceField>
      <ReferenceField
        source='familyGroupId'
        reference='FamilyGroups'
        link='show'
      >
        <ChipField source='id' />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const UserProfileShow = (props: any) => {
  return (
    <Show {...props} actions={<BasicShowActions />}>
      <TabbedShowLayout>
        <Tab label='Personal info'>
          <TextField source='ageType' />
          <TextField source='firstName' />
          <TextField source='lastName' />
          <TextField source='middleName' />
          <TextField source='suffixName' />
          <TextField source='displayName' />
          <SimpleDateField source='birthDate' />
          <TextField source='placeOfBirth' />
          <SimpleDateField source='deathDate' />
          <TextField source='placeOfDeath' />
          <ReferenceField
            source='profileImageId'
            reference='Images'
            link='show'
          >
            <FCImageField title='Profile image' source='id' />
          </ReferenceField>
        </Tab>
        <Tab label='Basic info'>
          <TextField source='id' />
          <TextField source='status' />
          <BooleanField source='active' />
          <SimpleDateField source='createdTimestamp' />
          <BooleanField source='reconciled' />
          <ReferenceField source='userId' reference='Users' link='show'>
            <ChipField source='id' />
          </ReferenceField>
          <ReferenceField
            source='familyGroupId'
            reference='FamilyGroups'
            link='show'
          >
            <ChipField source='id' />
          </ReferenceField>
        </Tab>
        <Tab label='Contact info'>
          <EmailField source='email' />
          <TextField source='mobilePhone' />
          <TextField source='preferredNotificationMethod' />
          <BooleanField source='acceptsPhysicalMailInvites' />
        </Tab>
        <Tab label='Address'>
          <TextField source='addressLine1' />
          <TextField source='addressLine2' />
          <TextField source='city' />
          <TextField source='state' />
          <TextField source='zip' />
        </Tab>
        <Tab label='Relatives'>
          <ReferenceField
            source='fatherProfileId.id'
            reference='UserProfiles'
            label={'Father profile'}
            link='show'
          >
            <ChipField source='id' />
          </ReferenceField>
          <BooleanField source='isFatherSlaveOwner' />
          <ReferenceField
            source='motherProfileId.id'
            reference='UserProfiles'
            label={'Mother profile'}
            link='show'
          >
            <ChipField source='id' />
          </ReferenceField>
          <BooleanField source='isMotherSlaveOwner' />
          <ArrayField label='Past spouses' source='pastSpousesProfiles'>
            <Datagrid>
              <ReferenceField
                label='User profile'
                source='pastSpouseUserProfileId'
                reference='UserProfiles'
                link='show'
              >
                <ChipField source='id' />
              </ReferenceField>
              <SimpleDateField
                label='Marriage data'
                source='marriageDate'
              ></SimpleDateField>
              <TextField
                label='Marriage place'
                source='placeOfMarriage'
              ></TextField>
            </Datagrid>
          </ArrayField>
          <UserProfileSpouseField label='Current spouse' />
          <ArrayField source='childrenProfileIds'>
            <SingleFieldList>
              <ReferenceField source='id' reference='UserProfiles' link='show'>
                <ChipField source='id' />
              </ReferenceField>
            </SingleFieldList>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const UserProfileEdit = (props: any) => {
  const dataProvider: DataProvider = useDataProvider();
  const [userProfile, setUserProfile] = useState<UserProfile | undefined>();
  useEffect(() => {
    dataProvider
      .getOne('UserProfiles', { id: props.id })
      .then(({ data }: GetOneResult<Record>) => {
        setUserProfile(data as UserProfile);
      });
  }, [dataProvider, props.id]);

  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label='Personal info'>
          <TextInput source='firstName' />
          <TextInput source='lastName' />
          <TextInput source='displayName' />
          <TextInput source='suffixName' />
          <TextInput source='middleName' />
          <TextInput source='ageType' />
          <DateInput source='birthDate' />
          <TextInput source='placeOfBirth' />
          <DateInput source='deathDate' />
          <TextInput source='placeOfDeath' />
          <FCImageInput source='profileImageId' title='Profile image' />
        </FormTab>
        <FormTab label='Basic info'>
          <TextInput source='id' disabled />
          <SelectInput
            disabled={false}
            source='status'
            choices={EnumUtils.EnumToSelectInputChoices(UserProfileStatus)}
          />
          <BooleanInput source='active' disabled />
          <DateTimeInput source='createdTimestamp' disabled />
          <BooleanInput source='reconciled' disabled />
          <TextInput source='userId' disabled />
          <TextInput source='familyGroupId' disabled />
        </FormTab>
        <FormTab label='Contact info'>
          <TextInput source='email' />
          <TextInput source='mobilePhone' />
          <ArrayInput source='preferredNotificationMethod'>
            <SimpleFormIterator defaultValue={{ a: '' }}>
              <TextInput />
            </SimpleFormIterator>
          </ArrayInput>
          <BooleanInput source='acceptsPhysicalMailInvites' />
        </FormTab>
        <FormTab label='Address'>
          <TextInput source='addressLine1' />
          <TextInput source='addressLine2' />
          <TextInput source='city' />
          <TextInput source='state' />
          <TextInput source='zip' />
        </FormTab>
        <FormTab label='Relatives'>
          <ReferenceInput
            label='Father profile'
            source='fatherProfileId.id'
            reference='UserProfiles'
            filter={{ familyGroupId: userProfile?.familyGroupId }}
          >
            <AutocompleteInput
              optionText={(value: UserProfile | undefined) =>
                value != null ? `${value.firstName} ${value.lastName}` : ''
              }
            />
          </ReferenceInput>
          <BooleanInput
            label='Is father slave owner'
            source='isFatherSlaveOwner'
          />
          <ReferenceInput
            label='Mother profile'
            source='motherProfileId.id'
            reference='UserProfiles'
            filter={{ familyGroupId: userProfile?.familyGroupId }}
          >
            <AutocompleteInput
              optionText={(value: UserProfile | undefined) =>
                value != null ? `${value.firstName} ${value.lastName}` : ''
              }
            />
          </ReferenceInput>
          <BooleanInput
            label='Is mother slave owner'
            source='isMotherSlaveOwner'
          />
          <ReferenceInput
            label='Current spouse profile'
            source='currentSpouseProfileId.id'
            reference='UserProfiles'
            filter={{ familyGroupId: userProfile?.familyGroupId }}
          >
            <AutocompleteInput
              optionText={(value: UserProfile | undefined) =>
                value != null ? `${value.firstName} ${value.lastName}` : ''
              }
            />
          </ReferenceInput>
          <DateInput label='Date of marriage' source='marriageDate' />
          <TextInput label='Place of marriage' source='placeOfMarriage' />
          <ArrayInput label='Past spouses' source='pastSpousesProfiles'>
            <SimpleFormIterator disableReordering>
              <ReferenceInput
                label='Past spouse profile'
                source='pastSpouseUserProfileId'
                reference='UserProfiles'
                filter={{ familyGroupId: userProfile?.familyGroupId }}
              >
                <AutocompleteInput
                  optionText={(value: UserProfile | undefined) =>
                    value != null ? `${value.firstName} ${value.lastName}` : ''
                  }
                />
              </ReferenceInput>
              <TextInput label='Place of marriage' source='placeOfMarriage' />
              <DateInput label='Date of marriage' source='marriageDate' />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const UserProfileSpouseFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const UserProfileSpouseFieldPartWrapper = styled.div`
  padding: 5px;
`;

export const UserProfileSpouseField = (props: any) => {
  const record = useRecordContext(props);
  return (
    <UserProfileSpouseFieldWrapper>
      <UserProfileSpouseFieldPartWrapper>
        <ReferenceField
          label='User profile'
          record={record}
          source='currentSpouseProfileId.id'
          reference='UserProfiles'
          link='show'
        >
          <ChipField source='id' />
        </ReferenceField>
      </UserProfileSpouseFieldPartWrapper>
      <UserProfileSpouseFieldPartWrapper>
        <SimpleDateField
          label='Marriage data'
          record={record}
          source='marriageDate'
        ></SimpleDateField>
      </UserProfileSpouseFieldPartWrapper>
      <UserProfileSpouseFieldPartWrapper>
        <TextField
          label='Marriage place'
          record={record}
          source='placeOfMarriage'
        ></TextField>
      </UserProfileSpouseFieldPartWrapper>
    </UserProfileSpouseFieldWrapper>
  );
};

UserProfileSpouseField.defaultProps = {
  addLabel: true,
};
