import { ConvertStringToOrderType } from '@backend/models';
import { HttpError } from 'react-admin';

import {
  familyGroupSuperuserModulePrivateRpc,
  getAuthTokenSafe,
  getManyFromRpc,
  getOneFromRpc,
  deleteOneRpc,
  deleteManyRpc,
  updateRpc,
  uploadImageToRecord,
} from '../utils';
import { BaseProvider } from './BaseProvider';

export class FamilyGroupProvider extends BaseProvider {
  public static getList(params: any): Promise<any> {
    if (params.sort.field === 'id') {
      params.sort.field = 'createdTimestamp';
      params.sort.order = 'desc';
    }
    return new Promise((resolve, reject) => {
      return familyGroupSuperuserModulePrivateRpc
        .getAllFamilyGroupsPaginated(
          getAuthTokenSafe(),
          undefined,
          params.sort.field,
          ConvertStringToOrderType(params.sort.order),
          params.filter.id,
          params.filter.ownerUserId,
          params.pagination.perPage,
          (params.pagination.page - 1) * params.pagination.perPage,
        )
        .then((data) => {
          resolve({
            data: data.items,
            total: data.total,
          });
        })
        .catch((error) => {
          reject(
            new HttpError(
              error.message || 'Unexpected error',
              error.httpCode,
              error,
            ),
          );
        });
    });
  }

  public static getMany(params: any): Promise<any> {
    return getManyFromRpc(
      familyGroupSuperuserModulePrivateRpc.getFamilyGroupsBulk.bind(
        familyGroupSuperuserModulePrivateRpc,
      ),
      params,
    );
  }

  public static getOne(params: any): Promise<any> {
    return getOneFromRpc(
      familyGroupSuperuserModulePrivateRpc.getFamilyGroupsBulk.bind(
        familyGroupSuperuserModulePrivateRpc,
      ),
      params,
    );
  }

  public static async update(params: any): Promise<any> {
    params.data = await uploadImageToRecord(
      params.data,
      'coverImageId',
      params.data?.ownerUserId,
    );
    return updateRpc(
      familyGroupSuperuserModulePrivateRpc.updateFamilyGroup.bind(
        familyGroupSuperuserModulePrivateRpc,
      ),
      params,
    );
  }

  public static delete(params: any): Promise<any> {
    return deleteOneRpc(
      familyGroupSuperuserModulePrivateRpc.deleteFamilyGroup.bind(
        familyGroupSuperuserModulePrivateRpc,
      ),
      params,
    );
  }

  public static deleteMany(params: any): Promise<any> {
    return deleteManyRpc(
      familyGroupSuperuserModulePrivateRpc.deleteFamilyGroup.bind(
        familyGroupSuperuserModulePrivateRpc,
      ),
      params,
    );
  }
}
