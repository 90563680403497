import { AssetSuperuserModulePrivateRpc } from '@backend/api_client';
import { Typography, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useRecordContext } from 'react-admin';
import { FileUtils, getAuthTokenSafe } from '../../utils';
import styled from 'styled-components';
import { listenerCount } from 'process';

const baseUrl = process.env.REACT_APP_API_DOMAIN!;
const assetSuperuserModulePrivateRpc: AssetSuperuserModulePrivateRpc = new AssetSuperuserModulePrivateRpc(baseUrl);

const StyledImage = styled.img`
  max-height: 160px;
  max-width: 320px;
`;

const NoImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 160px;
  min-width: 320px;
  border: 1px solid black;
`;

export const FCImageField = (props: any) => {
  const { source } = props;
  const record = useRecordContext(props);
  const [base64, setBase64] = useState<string | undefined>();
  const [error, setError] = useState<string | undefined>();
  
  useEffect(()=>{
    const authToken: string = getAuthTokenSafe();
    let imageId: number | undefined;
    if(source != null && source.length > 0){
      imageId = record[source];
    } else {
      // Due to react-admin typings bug
      imageId = (record as unknown) as number;
    }

    if(imageId == null && typeof(imageId) !== 'number'){
      return;
    }
    
    try{
      assetSuperuserModulePrivateRpc
        .retrieveImageFile(imageId, authToken)
        .then(FileUtils.ArrayBufferToBase64)
        .then(setBase64)
        .catch((e: any) => {
          setError('Image loading failed, please try later.')
        });
    } catch(e){
      setError('Image loading failed, please try later.')
    }
  });
  if(base64 != null){
    return <StyledImage src={`data:image/jpeg;charset=utf-8;base64,${base64}`}></StyledImage>;
  }
  return <NoImageWrapper>
    { error != null && 
      <Typography color="error">{error}</Typography>
    }
    { error == null && 
      <CircularProgress />
    }
  </NoImageWrapper>
}

FCImageField.defaultProps = {
  addLabel: true,
};