import React from 'react';
import {
  ArrayField,
  ChipField,
  Datagrid,
  Filter,
  List,
  NumberInput,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';

import { BasicShowActions, CustomPagination, SimpleDateField } from '.';
import { AlbumTextFieldWithTags } from './elements/AlbumTextFieldWithTags';

export const AlbumImageCommentFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='Id' source='id' />
  </Filter>
);

export const AlbumImageCommentList = (props: any) => (
  <List
    {...props}
    pagination={<CustomPagination />}
    title={'Album image comments'}
    filters={<AlbumImageCommentFilter />}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <AlbumTextFieldWithTags
        label={'Body'}
        source={'body'}
        tagsSource={'albumUserProfileTags'}
        ellipsis={20}
      />
      <ReferenceField source='albumImageId' reference='AlbumImages' link='show'>
        <ChipField source='id' />
      </ReferenceField>
      <ReferenceField
        source='creatorUserProfileId'
        reference='UserProfiles'
        link='show'
      >
        <ChipField source='id' />
      </ReferenceField>
      <SimpleDateField source='created' />
      <SimpleDateField source='updated' />
    </Datagrid>
  </List>
);

export const AlbumImageCommentShow = (props: any) => (
  <Show {...props} actions={<BasicShowActions />}>
    <TabbedShowLayout>
      <Tab label='Basic info'>
        <TextField source='id' />
        <AlbumTextFieldWithTags
          label='Body'
          source={'body'}
          tagsSource={'albumUserProfileTags'}
        />
        <ReferenceField
          source='albumImageId'
          reference='AlbumImages'
          link='show'
        >
          <ChipField source='id' />
        </ReferenceField>
        <ReferenceField
          source='creatorUserProfileId'
          reference='UserProfiles'
          link='show'
        >
          <ChipField source='id' />
        </ReferenceField>
        <SimpleDateField source='created' />
        <SimpleDateField source='updated' />
      </Tab>
      <Tab label='Reactions'>
        <ArrayField source='commentReactions'>
          <Datagrid>
            <TextField source='id' />
            <SimpleDateField source='created' />
            <ReferenceField
              source='ownerUserProfileId'
              reference='UserProfiles'
              link='show'
            >
              <ChipField source='id' />
            </ReferenceField>
            <TextField source='reactionType' />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
