import React from 'react';
import {
  ArrayField,
  ChipField,
  Datagrid,
  List,
  NumberField,
  BooleanField,
  TextInput,
  ReferenceField,
  Show,
  BooleanInput,
  ArrayInput,
  Edit,
  SimpleFormIterator,
  TabbedForm,
  FormTab,
  Tab,
  TabbedShowLayout,
  TextField,
  Filter,
  NumberInput,
} from 'react-admin';

import { OnlyEditShowActions } from './BasicShowActions';
import { OnlySaveToolbar } from './BasicEditActions';
import { CustomPagination } from './elements';

export const ReunionBudgetFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='Id' source='id' />
    <NumberInput label='Reunion id' source='reunionId' />
  </Filter>
);

export const ReunionBudgetList = (props: any) => (
  <List
    {...props}
    pagination={<CustomPagination />}
    title={'Reunion budgets'}
    bulkActionButtons={false}
    filters={<ReunionBudgetFilter />}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <NumberField source='maxBudget' />
      <NumberField source='totalEstimateCost' />
      <NumberField source='totalActualCost' />
      <NumberField source='totalCost' />
      <ReferenceField source='reunionId' reference='Reunions' link='show'>
        <ChipField source='id' />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const ReunionBudgetShow = (props: any) => (
  <Show {...props} actions={<OnlyEditShowActions />}>
    <TabbedShowLayout>
      <Tab label='Basic info'>
        <TextField source='id' />
        <NumberField source='maxBudget' />
        <NumberField source='totalEstimateCost' />
        <NumberField source='totalActualCost' />
        <NumberField source='totalCost' />
        <ReferenceField source='reunionId' reference='Reunions' link='show'>
          <ChipField source='id' />
        </ReferenceField>
      </Tab>
      <Tab label='Expense types'>
        <ArrayField source='expenseTypes'>
          <Datagrid>
            <TextField source='id' />
            <TextField source='name' />
            <BooleanField source='custom' />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const ReunionBudgetEdit = (props: any) => (
  <Edit {...props}>
    <TabbedForm toolbar={<OnlySaveToolbar />}>
      <FormTab label='Basic info'>
        <NumberInput source='id' disabled />
        <NumberInput source='maxBudget' />
        <NumberInput source='totalEstimateCost' disabled />
        <NumberInput source='totalActualCost' disabled />
        <NumberInput source='totalCost' disabled />
        <NumberInput source='reunionId' disabled />
      </FormTab>
      <FormTab label='Expense types'>
        <ArrayInput source='expenseTypes'>
          <SimpleFormIterator>
            <NumberInput source='id' label='Id' disabled />
            <TextInput source='name' label='Name' />
            <BooleanInput
              source='custom'
              label='Custom'
              initialValue={true}
              disabled
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);
