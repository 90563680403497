import { ConvertStringToOrderType } from '@backend/models';
import { HttpError } from 'react-admin';

import { reunionRsvpResponseSuperuserModulePrivateRpc, getAuthTokenSafe, getManyFromRpc, getOneFromRpc, deleteManyRpc, deleteOneRpc } from '../utils';
import { BaseProvider } from './BaseProvider';

export class ReunionRsvpResponseProvider extends BaseProvider {
  public static getList(params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return reunionRsvpResponseSuperuserModulePrivateRpc.getAllReunionRsvpResponsesPaginated(
        getAuthTokenSafe(),
        undefined,
        params.sort.field,
        ConvertStringToOrderType(params.sort.order), 
        params.filter.id,
        params.filter.reunionId,
        params.filter.userProfileId,
        params.filter.nonFamilyGroupGuestId,
        params.filter.payer,
        params.pagination.perPage,
        (params.pagination.page - 1) * params.pagination.perPage
      ).then(data => {
        resolve({
          data: data.items, total: data.total
        });
      })
        .catch(error => {
          reject(
            new HttpError(
              error.message || 'Unexpected error',
              error.httpCode,
              error
            )
          );
        })
    });
  }
  
  public static getMany(params: any): Promise<any> {
    return getManyFromRpc(reunionRsvpResponseSuperuserModulePrivateRpc.getReunionRsvpResponsesBulk.bind(reunionRsvpResponseSuperuserModulePrivateRpc), params);
  }

  public static getOne(params: any): Promise<any> {
    return getOneFromRpc(reunionRsvpResponseSuperuserModulePrivateRpc.getReunionRsvpResponsesBulk.bind(reunionRsvpResponseSuperuserModulePrivateRpc), params);
  }
     
  public static deleteMany(params: any): Promise<any> {
    return deleteManyRpc(reunionRsvpResponseSuperuserModulePrivateRpc.deleteRsvpResponse.bind(reunionRsvpResponseSuperuserModulePrivateRpc), params);
  }

  public static delete(params: any): Promise<any> {
    return deleteOneRpc(reunionRsvpResponseSuperuserModulePrivateRpc.deleteRsvpResponse.bind(reunionRsvpResponseSuperuserModulePrivateRpc), params);
  }
}