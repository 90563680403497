import { ConvertStringToOrderType } from '@backend/models';
import { FamilyGroup } from '@shared/models';
import { HttpError, UseGetOneHookValue } from 'react-admin';
import { FamilyGroupProvider } from '.';

import {
  reunionSuperuserModulePrivateRpc,
  getAuthTokenSafe,
  getManyFromRpc,
  getOneFromRpc,
  deleteOneRpc,
  deleteManyRpc,
  updateRpc,
  uploadImageToRecord,
} from '../utils';
import { BaseProvider } from './BaseProvider';

export class ReunionProvider extends BaseProvider {
  public static action(params: any): Promise<any> {
    if (params.action === 'charge') {
      return this.charge(params);
    }
    return Promise.reject(
      new HttpError('Not implemented', 501, 'Not implemented'),
    );
  }

  public static charge(params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return reunionSuperuserModulePrivateRpc
        .chargeReunion(params.id, getAuthTokenSafe())
        .then((data) => {
          resolve({
            data: data,
          });
        })
        .catch((error) => {
          reject(
            new HttpError(
              error.message || 'Unexpected error',
              error.httpCode,
              error,
            ),
          );
        });
    });
  }

  public static getList(params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return reunionSuperuserModulePrivateRpc
        .getAllReunionsPaginated(
          getAuthTokenSafe(),
          undefined,
          params.sort.field,
          ConvertStringToOrderType(params.sort.order),
          params.filter.id,
          params.filter.familyGroupId,
          params.filter.displayName,
          params.filter.status,
          params.pagination.perPage,
          (params.pagination.page - 1) * params.pagination.perPage,
          true,
        )
        .then((data) => {
          resolve({
            data: data.items,
            total: data.total,
          });
        })
        .catch((error) => {
          reject(
            new HttpError(
              error.message || 'Unexpected error',
              error.httpCode,
              error,
            ),
          );
        });
    });
  }

  public static getMany(params: any): Promise<any> {
    return getManyFromRpc(
      reunionSuperuserModulePrivateRpc.getReunionsBulkForSuperuser.bind(
        reunionSuperuserModulePrivateRpc,
      ),
      params,
    );
  }

  public static getOne(params: any): Promise<any> {
    return getOneFromRpc(
      reunionSuperuserModulePrivateRpc.getReunionsBulkForSuperuser.bind(
        reunionSuperuserModulePrivateRpc,
      ),
      params,
    );
  }

  public static async update(params: any): Promise<any> {
    // We need to make the FGO the owner for a new cover image
    const familyGroupResult: UseGetOneHookValue<FamilyGroup> =
      await FamilyGroupProvider.getOne({ id: params.data.familyGroupId });

    params.data = await uploadImageToRecord(
      params.data,
      'coverImageId',
      familyGroupResult.data?.ownerUserId,
    );
    return updateRpc(
      reunionSuperuserModulePrivateRpc.updateReunion.bind(
        reunionSuperuserModulePrivateRpc,
      ),
      params,
    );
  }

  public static delete(params: any): Promise<any> {
    return deleteOneRpc(
      reunionSuperuserModulePrivateRpc.deleteReunion.bind(
        reunionSuperuserModulePrivateRpc,
      ),
      params,
    );
  }

  public static deleteMany(params: any): Promise<any> {
    return deleteManyRpc(
      reunionSuperuserModulePrivateRpc.deleteReunion.bind(
        reunionSuperuserModulePrivateRpc,
      ),
      params,
    );
  }
}
