import React from 'react';
import {
  ArrayField,
  BooleanField,
  ChipField,
  Datagrid,
  DateInput,
  TabbedForm,
  FormTab,
  EmailField,
  SimpleForm,
  BooleanInput,
  DateTimeInput,
  NumberInput,
  ReferenceField,
  Edit,
  TextInput,
  Create,
  List,
  Show,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  Filter,
  useMutation,
  useNotify,
  useRefresh,
  Button,
  BulkDeleteButton,
} from 'react-admin';
import Lock from '@material-ui/icons/Lock';

import { CustomPagination, SimpleDateField } from './';

import { BasicShowActions } from './BasicShowActions';

export const UserBulkActions = (props: any) => {
  const { basePath, selectedIds } = props;
  const [mutate] = useMutation();
  const notify = useNotify();
  const refresh = useRefresh();

  return (
    <>
      <Button
        startIcon={<Lock />}
        label='Reset Password'
        basePath={basePath}
        disabled={false}
        onClick={() => {
          mutate(
            {
              type: 'action',
              resource: 'Users',
              payload: { ids: selectedIds, action: 'resetPasswordsBulk' },
            },
            {
              onFailure: (error: any) => {
                notify(error.message, 'error');
                refresh();
              },
              onSuccess: () => {
                notify('Success!', 'success');
                refresh();
              },
              mutationMode: 'pessimistic',
            },
          );
        }}
      />
      <BulkDeleteButton
        {...props}
        undoable={false}
        confirmContent='Are you sure you want to delete? This action cannot be undone.'
        confirmTitle='Confirm'
      />
    </>
  );
};

export const UserFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='Id' source='id' />
    <TextInput label='Email' source='email' />
    <TextInput label='First name' source='firstName' />
    <TextInput label='Last name' source='lastName' />
    <TextInput label='Mobile phone' source='phone' />
  </Filter>
);

export const UserList = (props: any) => (
  <List
    {...props}
    pagination={<CustomPagination />}
    title={'Users'}
    filters={<UserFilter />}
    bulkActionButtons={<UserBulkActions />}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <EmailField source='email' />
      <TextField source='mobilePhone' />
      <TextField source='firstName' />
      <TextField source='lastName' />
      <TextField source='middleName' sortable={false} />
      <TextField source='suffixName' sortable={false} />
      <SimpleDateField source='birthDate' />
      <BooleanField source='active' />
      <SimpleDateField source='createdTimestamp' />
    </Datagrid>
  </List>
);

export const UserShow = (props: any) => (
  <Show {...props} actions={<BasicShowActions />}>
    <TabbedShowLayout>
      <Tab label='Basic info'>
        <TextField source='id' />
        <TextField source='userLevel' />
        <TextField source='userAgency' />
        <BooleanField source='active' />
        <SimpleDateField source='createdTimestamp' />
      </Tab>
      <Tab label='Contact info'>
        <EmailField source='email' />
        <TextField source='mobilePhone' />
      </Tab>
      <Tab label='Personal info'>
        <TextField source='ageType' />
        <TextField source='firstName' />
        <TextField source='lastName' />
        <TextField source='middleName' />
        <TextField source='suffixName' />
        <SimpleDateField source='birthDate' format='MM.DD.YYYY' />
      </Tab>
      <Tab label='Address'>
        <TextField source='addressLine1' />
        <TextField source='addressLine2' />
        <TextField source='city' />
        <TextField source='state' />
        <TextField source='zip' />
      </Tab>
      <Tab label='Profiles'>
        <ArrayField source='userProfiles'>
          <SingleFieldList>
            <ReferenceField source='id' reference='UserProfiles' link='show'>
              <ChipField source='id' />
            </ReferenceField>
          </SingleFieldList>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const UserEdit = (props: any) => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label='Basic info'>
        <TextInput source='id' disabled />
        <TextInput source='userLevel' disabled />
        <TextInput source='userAgency' disabled />
        <BooleanInput source='active' disabled />
        <DateTimeInput disabled source='createdTimestamp' />
      </FormTab>
      <FormTab label='Contact info'>
        <TextInput source='email' />
        <TextInput source='mobilePhone' />
      </FormTab>
      <FormTab label='Personal info'>
        <TextInput source='ageType' />
        <TextInput source='firstName' />
        <TextInput source='lastName' />
        <TextInput source='middleName' />
        <TextInput source='suffixName' />
        <DateInput source='birthDate' />
      </FormTab>
      <FormTab label='Address'>
        <TextInput source='addressLine1' />
        <TextInput source='addressLine2' />
        <TextInput source='city' />
        <TextInput source='state' />
        <TextInput source='zip' />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const UserCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source='email' />
      <TextInput source='mobilePhone' />
      <TextInput source='password' />

      <TextInput source='userLevel' />
      <TextInput source='userAgency' />

      <TextInput source='ageType' />
      <TextInput source='firstName' />
      <TextInput source='lastName' />
      <TextInput source='middleName' />
      <TextInput source='suffixName' />
      <DateInput source='birthDate' />

      <TextInput source='addressLine1' />
      <TextInput source='addressLine2' />
      <TextInput source='city' />
      <TextInput source='state' />
      <TextInput source='zip' />
    </SimpleForm>
  </Create>
);
