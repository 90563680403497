import * as React from 'react';
import { FC, useState, useEffect } from 'react';
import {
  Pagination as DefaultPagination,
  PaginationProps,
  useListContext,
} from 'react-admin';
import { TextField, InputAdornment } from '@material-ui/core';

export const CustomPagination: FC<PaginationProps> = (props) => {
  const { total, page, perPage, setPage } = useListContext();
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    setValue(String(page));
  }, [page]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPage = parseInt(event.target.value, 10);
    if (
      !isNaN(newPage) &&
      newPage >= 1 &&
      newPage <= Math.ceil(total / perPage)
    ) {
      setPage(newPage);
      setValue(event.target.value);
    } else {
      setValue(event.target.value);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <DefaultPagination {...props} />
      <TextField
        type='number'
        value={value}
        onChange={handleInputChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>Go to page:</InputAdornment>
          ),
        }}
        style={{ marginLeft: 16, width: 150 }}
      />
    </div>
  );
};
