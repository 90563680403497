export class EnumUtils{
  public static EnumToSelectInputChoices<T>(
    enumVariable: T,
    toReadable?: (value: T) => string
  ): Array<{id: T, name: T}> {
    const result: Array<any> = new Array();
    const enumValues = Object.values(enumVariable);
    for (let i = 0; i < enumValues.length; i++) {
      const value: T = enumValues[i];
      
      if(toReadable != null){
        result.push({
          id: value,
          name: toReadable(value)
        })
      } else {
        result.push({
          id: value,
          name: value
        })
      }
    }

    return result;
  }
}