import React from 'react';
import {
  Filter,
  TextInput,
  NumberInput,
  Datagrid,
  List,
  TextField,
  ReferenceField,
  ChipField,
} from 'react-admin';

import { CustomPagination } from './elements';

export const UserDeviceFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='Id' source='id' />
    <TextInput label='Fcm token' source='fcmToken' />
    <NumberInput label='Owner user id' source='ownerUserId' />
  </Filter>
);

export const UserDeviceList = (props: any) => (
  <List
    {...props}
    pagination={<CustomPagination />}
    title={'User devices'}
    filters={<UserDeviceFilter />}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='fcmToken' />
      <TextField source='deviceName' />
      <TextField source='deviceId' />
      <TextField source='os' />
      <TextField source='systemVersion' />
      <TextField source='version' />
      <TextField source='userId' />
      <ReferenceField source='userId' reference='Users' link='show'>
        <ChipField source='id' />
      </ReferenceField>
    </Datagrid>
  </List>
);
