import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { FamilyGroup } from '@shared/models';
import React, { useEffect, useState } from 'react';
import {
  ChipField,
  Datagrid,
  Filter,
  GetOneResult,
  Record,
  List,
  NumberInput,
  ReferenceField,
  TextField,
  TextInput,
  useDataProvider,
  useRecordContext,
} from 'react-admin';
import styled from 'styled-components';

import { CustomPagination } from './elements';

const CheckIconStyled = styled(CheckIcon)`
  width: 20px;
  height: 20px;
`;

const CloseIconStyled = styled(CloseIcon)`
  width: 20px;
  height: 20px;
`;

export const FamilyGroupManagerFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label='First name' source='firstName' />
    <TextInput label='Last name' source='lastName' />
    <NumberInput label='User profile id' source='userProfileId' />
    <NumberInput label='Family group id' source='familyGroupId' />
    <NumberInput label='User id' source='userId' />
  </Filter>
);

export const FamilyGroupManagerList = (props: any) => (
  <List
    {...props}
    pagination={<CustomPagination />}
    title={'Family group managers'}
    filters={<FamilyGroupManagerFilter />}
  >
    <Datagrid>
      <TextField source='firstName' />
      <TextField source='lastName' />
      <ReferenceField
        label='User profile'
        sortBy='userProfileId'
        source='id'
        reference='UserProfiles'
        link='show'
      >
        <ChipField source='id' />
      </ReferenceField>
      <ReferenceField
        source='familyGroupId'
        sortBy='familyGroupId'
        reference='FamilyGroups'
        link='show'
      >
        <ChipField source='id' />
      </ReferenceField>
      <ReferenceField source='userId' reference='Users' link='show'>
        <ChipField source='id' />
      </ReferenceField>
      <IsFamilyGroupManagerFamilyGroupOwner label='Is FGO' />
    </Datagrid>
  </List>
);

export const IsFamilyGroupManagerFamilyGroupOwner = (props: any) => {
  const dataProvider = useDataProvider();
  const [familyGroup, setFamilyGroup] = useState<FamilyGroup | undefined>();
  const record = useRecordContext(props);
  useEffect(() => {
    dataProvider
      .getOne('FamilyGroups', { id: record.familyGroupId })
      .then(({ data }: GetOneResult<Record>) => {
        setFamilyGroup(data as FamilyGroup);
      });
  }, [dataProvider, record.familyGroupId]);

  if (familyGroup == null) {
    return <div></div>;
  }
  const isOwner: boolean = familyGroup.ownerUserId === record.userId;
  if (isOwner) {
    return <CheckIconStyled />;
  }
  return <CloseIconStyled />;
};
