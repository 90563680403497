import { SuperuserLoginResponse } from "@backend/models";

export function getAuthTokenSafe(): string {
  let token = ''
  const authDataStr: string | null = localStorage.getItem('auth_data');
  if (authDataStr != null) {
    const authData: SuperuserLoginResponse = JSON.parse(authDataStr);
    token = authData.superuserCredentials.superuserAccessToken.token;
  }

  return token
}

export function getAuthData(): SuperuserLoginResponse | undefined {
  const authDataStr: string | null = localStorage.getItem('auth_data');
  if (authDataStr != null) {
    const authData: SuperuserLoginResponse = JSON.parse(authDataStr);
    return authData;
  }
  return;
}

export function setAuthData(data: SuperuserLoginResponse): void {
  localStorage.setItem('auth_data', JSON.stringify(data));
}