import { TutorialVideoType, VideoFile, VideoFileStatus } from '@backend/api_client/dist/models';
import React from 'react';
import { BooleanField, Filter, NumberInput, Datagrid, List, TextField, ReferenceField, ChipField, Show, TabbedShowLayout, Tab, Create, SimpleForm, FileInput, FileField, Button, useShowController, ReferenceInput, SelectInput, AutocompleteInput, TextInput, Edit } from 'react-admin';
import { EnumUtils } from '../utils';

import { OnlyDeleteShowActions, SimpleDateField } from './';
import { FCImageField } from './elements';
import { FCVideoField } from './elements/FCVideoField';

export const TutorialVideoFilter = (props: any) => (
  <Filter {...props}>
  </Filter>
);

export const TutorialVideoList = (props: any) => (
  <List {...props} title={'Videos'} hasCreate hasShow={false} hasEdit={false} pagination={<div></div>} >
    <Datagrid rowClick="edit">
      <ReferenceField source="videoFileId" reference="Videos" link="show">
        <ChipField source="id" />
      </ReferenceField>
      <TextField source="type" />
      <TextField source="title" />
      <TextField source="description" />
    </Datagrid>
  </List>
);

export const TutorialVideoCreate = (props: any) => (
  <Create {...props}> 
      <SimpleForm>
        <SelectInput disabled={false} source="type" choices={EnumUtils.EnumToSelectInputChoices(TutorialVideoType)} />
        <ReferenceInput 
          label="Video id" 
          source="videoFileId"
          reference="Videos"
          filterToQuery={searchText => ({ id: +searchText })}
         >
            <AutocompleteInput options={{InputProps:{type: 'number'}}} optionText={(choice: VideoFile) => choice? `${choice.id}` : ``} />
        </ReferenceInput>
        <TextInput source="title" />
        <TextInput source="description" />
      </SimpleForm>
  </Create>
);

export const TutorialVideoEdit = (props: any) => {
  return <Edit {...props}>
    <SimpleForm>
        <SelectInput disabled={false} source="type" choices={EnumUtils.EnumToSelectInputChoices(TutorialVideoType)} />
        <TextInput source="videoFileId" disabled/>
        <TextInput source="title" />
        <TextInput source="description" />
    </SimpleForm>
  </Edit>
};
