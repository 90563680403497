import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import {
  ArrayField,
  ChipField,
  Datagrid,
  List,
  NumberField,
  ReferenceField,
  FunctionField,
  TextField,
  Show,
  NumberInput,
  Filter,
  Tab,
  TabbedShowLayout,
} from 'react-admin';

import { BasicShowActions } from './BasicShowActions';
import { CustomPagination } from './elements';

export const ReunionRsvpResponseFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='Id' source='id' />
    <NumberInput label='Reunion id' source='reunionId' />
    <NumberInput label='User profile id' source='userProfileId' />
    <NumberInput
      label='Non family group guest id'
      source='nonFamilyGroupGuestId'
    />
    <NumberInput label='Payer' source='payer' />
  </Filter>
);

export const ReunionRsvpResponseList = (props: any) => (
  <List
    {...props}
    pagination={<CustomPagination />}
    title={'Reunion rsvp responses'}
    filters={<ReunionRsvpResponseFilter />}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='response' />
      <TextField source='comment' />
      <ReferenceField source='reunionId' reference='Reunions' link='show'>
        <ChipField source='id' />
      </ReferenceField>
      <ReferenceField
        source='userProfileId'
        reference='UserProfiles'
        link='show'
      >
        <ChipField source='id' />
      </ReferenceField>
      <TextField source='nonFamilyGroupGuestId' />
      <TextField source='relationship' />
      <ReferenceField source='payer' reference='Users' link='show'>
        <ChipField source='id' />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const ReunionRsvpResponseShow = (props: any) => (
  <Show {...props} actions={<BasicShowActions />}>
    <TabbedShowLayout>
      <Tab label='Reunion response'>
        <TextField source='id' />
        <TextField source='response' />
        <TextField source='comment' />
        <ReferenceField source='reunionId' reference='Reunions' link='show'>
          <ChipField source='id' />
        </ReferenceField>
        <ReferenceField
          source='userProfileId'
          reference='UserProfiles'
          link='show'
        >
          <ChipField source='id' />
        </ReferenceField>
        <TextField source='nonFamilyGroupGuestId' />
        <TextField source='relationship' />
        <ReferenceField source='payer' reference='Users' link='show'>
          <ChipField source='id' />
        </ReferenceField>
        <TextField source='userAgeType' />
        <TextField source='token' />
      </Tab>
      <Tab label='Activity responses'>
        <ArrayField source='activityResponses'>
          <ActivityResponsesGrid />
        </ArrayField>
      </Tab>
      <Tab label='Addon responses'>
        <ArrayField source='addonResponses'>
          <Datagrid>
            <TextField source='id' />
            <TextField source='response' />
            <TextField source='addonId' />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const activityResponsesGridCardStyle = {
  minWidth: 500,
  minHeight: 300,
  margin: '0.5em',
  display: 'inline-block',
  verticalAlign: 'top',
};

export const ActivityResponsesGrid = ({ ids, data, basePath }: any) => (
  <div style={{ margin: '1em' }}>
    {ids.map((id: any) => (
      <Card key={id} style={activityResponsesGridCardStyle}>
        <CardHeader
          title={
            <ReferenceField
              basePath={'/ReunionActivities'}
              record={data[id]}
              source='activityId'
              reference='ReunionActivities'
              link='show'
            >
              <TextField source='displayName' />
            </ReferenceField>
          }
          subheader={
            <ReferenceField
              basePath={'/ReunionActivities'}
              record={data[id]}
              source='activityId'
              reference='ReunionActivities'
              link='show'
            >
              <TextField source='id' />
            </ReferenceField>
          }
        />
        <CardContent>
          <FunctionField
            record={data[id]}
            render={(record: any) => `Response: ${record.response}`}
          />
          {data[id].addonResponses.length > 0 ? (
            <ArrayField record={data[id]} source='addonResponses'>
              <Datagrid>
                <TextField source='id' />
                <TextField source='response' />
                <TextField source='addonId' />
              </Datagrid>
            </ArrayField>
          ) : null}
          {data[id].questionResponses.length > 0 ? (
            <ArrayField record={data[id]} source='questionResponses'>
              <Datagrid>
                <TextField source='id' />
                <TextField source='response' />
                <TextField source='additionalQuestionId' />
              </Datagrid>
            </ArrayField>
          ) : null}
        </CardContent>
      </Card>
    ))}
  </div>
);
ActivityResponsesGrid.defaultProps = {
  data: {},
  ids: [],
};
