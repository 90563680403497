import React from 'react';
import {
  TextInput,
  Datagrid,
  Filter,
  NumberInput,
  NumberField,
  List,
  TextField,
  ReferenceField,
  ChipField,
} from 'react-admin';
import { CustomPagination, SimpleDateField } from './';

export const BillFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='Id' source='id' />
    <NumberInput label='User id' source='userId' />
    <NumberInput label='Payment intent id' source='paymentIntentId' />
    <TextInput label='Status' source='status' />
  </Filter>
);

export const BillList = (props: any) => (
  <List
    {...props}
    pagination={<CustomPagination />}
    title={'Bills'}
    bulkActionButtons={false}
    filters={<BillFilter />}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <ReferenceField source='userId' reference='Users' link='show'>
        <ChipField source='id' />
      </ReferenceField>
      <ReferenceField
        source='stripePaymentIntentId'
        reference='StripePaymentIntents'
        link='show'
      >
        <ChipField source='id' />
      </ReferenceField>
      <NumberField source='amount' />
      <SimpleDateField source='payByTimestamp' />
      <SimpleDateField source='paidTimestamp' />
      <TextField source='billStatus' />
      <TextField source='description' />
    </Datagrid>
  </List>
);
