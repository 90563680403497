import React from 'react';
import {
  ArrayField,
  BooleanField,
  ChipField,
  Datagrid,
  List,
  NumberField,
  ReferenceField,
  Show,
  Tab,
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  BooleanInput,
  DateTimeInput,
  NumberInput,
  TabbedShowLayout,
  TextField,
  Filter,
} from 'react-admin';

import {
  CustomPagination,
  FCImageField,
  FCImageInput,
  SimpleDateField,
} from './';

import { BasicShowActions } from './BasicShowActions';

export const ReunionActivityFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='Id' source='id' />
    <NumberInput label='Reunion id' source='reunionId' />
    <NumberInput label='Host user id' source='hostUserId' />
  </Filter>
);

export const ReunionActivityList = (props: any) => (
  <List
    {...props}
    pagination={<CustomPagination />}
    title={'Reunion activities'}
    filters={<ReunionActivityFilter />}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='paymentCategory' />
      <TextField source='displayName' />
      <SimpleDateField source='startTimestamp' />
      <SimpleDateField source='endTimestamp' />
      <BooleanField source='active' />
      <BooleanField source='canceled' />
      <TextField source='description' />
      <ReferenceField source='reunionId' reference='Reunions' link='show'>
        <ChipField source='id' />
      </ReferenceField>
      <ReferenceField source='hostUserId' reference='Users' link='show'>
        <ChipField source='id' />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const ReunionActivityShow = (props: any) => (
  <Show {...props} actions={<BasicShowActions />}>
    <TabbedShowLayout>
      <Tab label='Basic info'>
        <TextField source='id' />
        <TextField source='paymentCategory' />
        <TextField source='paymentMethod' />
        <TextField source='displayName' />
        <BooleanField source='active' />
        <BooleanField source='canceled' />
        <TextField source='hyperlink' />
        <TextField source='description' />
        <ReferenceField source='reunionId' reference='Reunions' link='show'>
          <ChipField source='id' />
        </ReferenceField>
        <ReferenceField source='hostUserId' reference='Users' link='show'>
          <ChipField source='id' />
        </ReferenceField>
        <ReferenceField source='coverImageId' reference='Images' link='show'>
          <FCImageField title='Cover image' source='id' />
        </ReferenceField>
      </Tab>
      <Tab label='Timestamps and location'>
        <SimpleDateField source='startTimestamp' />
        <SimpleDateField source='endTimestamp' />
        <TextField source='locationTitle' />
        <TextField source='locationTimezone' />
        <TextField source='locationAddress' />
        <NumberField source='lat' />
        <NumberField source='lng' />
        <TextField source='neLat' />
        <TextField source='neLng' />
        <TextField source='swLat' />
        <TextField source='swLng' />
      </Tab>
      <Tab label='Cost'>
        <NumberField source='adultCost' />
        <NumberField source='teenCost' />
        <NumberField source='childCost' />
        <NumberField source='infantCost' />
        <NumberField source='seniorCost' />
      </Tab>
      <Tab label='Face control'>
        <TextField source='attire' />
        <BooleanField source='adultsOnly' />
        <TextField source='adultsOnlyDetails' />
        <BooleanField source='allowsGuests' />
        <NumberField source='guestCountLimit' />
        <NumberField source='maxCapacity' />
      </Tab>
      <Tab label='Addons'>
        <ArrayField source='addons'>
          <Datagrid>
            <TextField source='id' />
            <TextField source='displayName' />
            <TextField source='cost' />
            <TextField source='nextAddonId' />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label='Todo'>
        <ArrayField source='todoList'>
          <Datagrid>
            <TextField source='id' />
            <TextField source='title' />
            <TextField source='completed' />
            <TextField source='nextTodoEntryId' />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label='Additional questions'>
        <ArrayField source='additionalQuestions'>
          <Datagrid>
            <TextField source='id' />
            <TextField source='title' />
            <TextField source='nextAdditionalQuestionId' />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const ReunionActivityEdit = (props: any) => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label='Basic info'>
        <TextInput source='id' disabled />
        <TextInput source='paymentCategory' />
        <TextInput source='paymentMethod' />
        <TextInput source='displayName' />
        <BooleanInput source='active' disabled />
        <BooleanInput source='canceled' disabled />
        <TextInput source='hyperlink' />
        <TextInput source='description' />
        <TextInput source='reunionId' disabled />
        <FCImageInput source='coverImageId' title='Cover image' />
        <TextInput source='hostUserId' disabled />
      </FormTab>
      <FormTab label='Timestamps and location'>
        <DateTimeInput source='startTimestamp' />
        <DateTimeInput source='endTimestamp' />
        <TextInput source='locationTitle' />
        <TextInput source='locationTimezone' />
        <TextInput source='locationAddress' />
        <NumberInput source='lat' />
        <NumberInput source='lng' />
        <NumberInput source='neLat' />
        <NumberInput source='neLng' />
        <NumberInput source='swLat' />
        <NumberInput source='swLng' />
      </FormTab>
      <FormTab label='Cost'>
        <NumberInput source='adultCost' />
        <NumberInput source='teenCost' />
        <NumberInput source='childCost' />
        <NumberInput source='infantCost' />
        <NumberInput source='seniorCost' />
      </FormTab>
      <FormTab label='Face control'>
        <TextInput source='attire' />
        <BooleanInput source='adultsOnly' />
        <TextInput source='adultsOnlyDetails' />
        <BooleanInput source='allowsGuests' />
        <NumberInput source='guestCountLimit' />
        <NumberInput source='maxCapacity' />
      </FormTab>
    </TabbedForm>
  </Edit>
);
