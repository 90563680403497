import React from 'react';
import {
  NumberInput,
  Filter,
  Datagrid,
  List,
  TextField,
  ReferenceField,
  ChipField,
} from 'react-admin';

import { CustomPagination } from './elements';

export const StripeCustomerFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='Id' source='id' />
    <NumberInput label='User id' source='userId' />
  </Filter>
);

export const StripeCustomerList = (props: any) => (
  <List
    {...props}
    pagination={<CustomPagination />}
    title={'Stripe customers'}
    bulkActionButtons={false}
    filters={<StripeCustomerFilter />}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='customerId' />
      <ReferenceField source='userId' reference='Users' link='show'>
        <ChipField source='id' />
      </ReferenceField>
      <TextField source='name' />
      <TextField source='phone' />
    </Datagrid>
  </List>
);
