import React from 'react';
import {
  BooleanField,
  Filter,
  NumberInput,
  Datagrid,
  List,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';

import { OnlyDeleteShowActions } from './BasicShowActions';
import { CustomPagination, FCImageField, SimpleDateField } from './';

export const ImageFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='Id' source='id' />
  </Filter>
);

export const ImageList = (props: any) => (
  <List
    {...props}
    pagination={<CustomPagination />}
    title={'Images'}
    filters={<ImageFilter />}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='s3Bucket' />
      <TextField source='s3KeyRaw' />
      <TextField source='status' />
      <BooleanField source='active' />
      <SimpleDateField source='created' />
    </Datagrid>
  </List>
);

export const ImageShow = (props: any) => (
  <Show {...props} actions={<OnlyDeleteShowActions />}>
    <TabbedShowLayout>
      <Tab label='Basic info'>
        <TextField source='id' />
        <TextField source='status' />
        <BooleanField source='active' />
        <SimpleDateField source='created' />
        <FCImageField source='id' label='Image' />
      </Tab>
      <Tab label='Amazon s3 info'>
        <TextField source='s3Bucket' />
        <TextField source='s3KeyRaw' />
        <TextField source='s3KeyOriginal' />
        <TextField source='s3KeySmall' />
        <TextField source='s3KeyLarge' />
        <TextField source='s3KeyMedium' />
        <TextField source='s3KeyThumbnail' />
        <TextField source='contentTypeRaw' />
        <TextField source='contentTypeOriginal' />
        <TextField source='contentTypeSmall' />
        <TextField source='contentTypeLarge' />
        <TextField source='contentTypeMedium' />
        <TextField source='contentTypeThumbnail' />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
