import React from 'react';
import {
  Filter,
  NumberInput,
  TextInput,
  Datagrid,
  List,
  TextField,
  ReferenceField,
  ChipField,
  NumberField,
  EmailField,
} from 'react-admin';

import { CustomPagination, SimpleDateField } from './';

export const StripePayoutFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='Id' source='id' />
    <NumberInput label='Recipient id' source='recipientId' />
    <TextInput label='Status' source='status' />
  </Filter>
);

export const StripePayoutList = (props: any) => (
  <List
    {...props}
    pagination={<CustomPagination />}
    title={'Stripe payouts'}
    bulkActionButtons={false}
    filters={<StripePayoutFilter />}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='accountId' />
      <TextField source='bankAccountId' />
      <TextField source='payoutId' />
      <SimpleDateField source='created' />
      <ReferenceField source='recipientId' reference='Users' link='show'>
        <ChipField source='id' />
      </ReferenceField>
      <NumberField source='amount' />
      <TextField source='payoutStatus' />
      <TextField source='firstName' />
      <TextField source='lastName' />
      <EmailField source='email' />
      <TextField source='description' />
    </Datagrid>
  </List>
);
