import React from 'react';
import {
  Datagrid,
  Filter,
  NumberInput,
  List,
  TextField,
  ReferenceField,
  ChipField,
} from 'react-admin';

import { CustomPagination } from './elements';

export const StripeBankAccountFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='Id' source='id' />
    <NumberInput label='User id' source='userId' />
  </Filter>
);

export const StripeBankAccountList = (props: any) => (
  <List
    {...props}
    pagination={<CustomPagination />}
    title={'Stripe bank accounts'}
    bulkActionButtons={false}
    filters={<StripeBankAccountFilter />}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <ReferenceField source='userId' reference='Users' link='show'>
        <ChipField source='id' />
      </ReferenceField>
      <TextField source='bankAccountId' />
    </Datagrid>
  </List>
);
