import React from 'react';
import {
  ArrayField,
  ChipField,
  Datagrid,
  Filter,
  List,
  NumberField,
  NumberInput,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';

import {
  BasicShowActions,
  CustomPagination,
  FCImageField,
  SimpleDateField,
} from '.';
import { AlbumTextFieldWithTags } from './elements/AlbumTextFieldWithTags';

export const AlbumImageFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='Id' source='id' />
  </Filter>
);

export const AlbumImageList = (props: any) => (
  <List
    {...props}
    pagination={<CustomPagination />}
    title={'Album images'}
    filters={<AlbumImageFilter />}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='title' />
      <ReferenceField source='albumId' reference='Albums' link='show'>
        <ChipField source='id' />
      </ReferenceField>
      <ReferenceField
        source='creatorUserProfileId'
        reference='UserProfiles'
        link='show'
      >
        <ChipField source='id' />
      </ReferenceField>
      <ReferenceField source='imageFileId' reference='Images' link='show'>
        <ChipField source='id' />
      </ReferenceField>
      <AlbumTextFieldWithTags
        label={'Tags'}
        tagsSource={'albumUserProfileTags'}
        ellipsis={20}
      />
      <SimpleDateField source='created' />
      <SimpleDateField source='updated' />
    </Datagrid>
  </List>
);

export const AlbumImageShow = (props: any) => (
  <Show {...props} actions={<BasicShowActions />}>
    <TabbedShowLayout>
      <Tab label='Basic info'>
        <TextField source='id' />
        <TextField source='title' />
        <ReferenceField
          source='familyGroupId'
          reference='FamilyGroups'
          link='show'
        >
          <ChipField source='id' />
        </ReferenceField>
        <ReferenceField
          source='creatorUserProfileId'
          reference='UserProfiles'
          link='show'
        >
          <ChipField source='id' />
        </ReferenceField>
        <ReferenceField source='imageFileId' reference='Images' link='show'>
          <FCImageField source='id' title='Image' />
        </ReferenceField>
        <ArrayField source='taggedUserProfiles'>
          <SingleFieldList>
            <ReferenceField source='id' reference='UserProfiles' link='show'>
              <ChipField source='id' />
            </ReferenceField>
          </SingleFieldList>
        </ArrayField>
        <AlbumTextFieldWithTags
          label={'Tags'}
          tagsSource={'albumUserProfileTags'}
          ellipsis={20}
        />
        <SimpleDateField source='created' />
        <SimpleDateField source='updated' />
      </Tab>
      <Tab label='Comments'>
        <ReferenceManyField
          pagination={<Pagination />}
          label='Comments'
          source='id'
          reference='AlbumImageComments'
          target='albumImageId'
        >
          <Datagrid rowClick='show'>
            <TextField source='id' />
            <TextField source='body' />
            <ReferenceField
              source='creatorUserProfileId'
              reference='UserProfiles'
              link='show'
            >
              <ChipField source='id' />
            </ReferenceField>
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label='Reactions'>
        <ArrayField source='reactions'>
          <Datagrid>
            <TextField source='id' />
            <SimpleDateField source='created' />
            <ReferenceField
              source='ownerUserProfileId'
              reference='UserProfiles'
              link='show'
            >
              <ChipField source='id' />
            </ReferenceField>
            <TextField source='reactionType' />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label='Tagged user profiles'>
        <ArrayField source='albumUserProfileTags'>
          <Datagrid>
            <NumberField source='id' />
            <ReferenceField
              source='taggedUserProfileId'
              reference='UserProfiles'
              link='show'
            >
              <ChipField label='Profile Id' source='id' />
            </ReferenceField>
            <TextField source='position' />
            <TextField source='displayName' />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
