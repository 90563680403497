import React from 'react';
import {
  Filter,
  NumberInput,
  Datagrid,
  List,
  TextField,
  ReferenceField,
  ChipField,
} from 'react-admin';

import { CustomPagination } from './elements';

export const StripePaymentMethodFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='Id' source='id' />
    <NumberInput label='User id' source='userId' />
  </Filter>
);

export const StripePaymentMethodList = (props: any) => (
  <List
    {...props}
    pagination={<CustomPagination />}
    title={'Stripe payment methods'}
    bulkActionButtons={false}
    filters={<StripePaymentMethodFilter />}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='paymentMethodId' />
      <ReferenceField source='userId' reference='Users' link='show'>
        <ChipField source='id' />
      </ReferenceField>
    </Datagrid>
  </List>
);
