import { VideoFileStatus } from '@backend/api_client/dist/models';
import React from 'react';
import {
  BooleanField,
  Filter,
  NumberInput,
  Datagrid,
  List,
  TextField,
  ReferenceField,
  ChipField,
  Show,
  TabbedShowLayout,
  Tab,
  Create,
  SimpleForm,
  FileInput,
  FileField,
  useShowController,
} from 'react-admin';

import { OnlyDeleteShowActions, SimpleDateField } from './';
import { CustomPagination, FCImageField } from './elements';
import { FCVideoField } from './elements/FCVideoField';

export const VideoFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='Id' source='id' />
  </Filter>
);

export const VideoList = (props: any) => (
  <List {...props} pagination={<CustomPagination />} title={'Videos'} hasCreate>
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='status' />
      <SimpleDateField source='created' />
      <SimpleDateField source='updated' />
      <BooleanField source='active' />
      <TextField source='fileName' />
      <TextField source='transcodeJobId' />
      <ReferenceField
        source='thumbnailImageFileId'
        reference='Images'
        link='show'
      >
        <ChipField source='id' />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const VideoShow = (props: any) => {
  const { record } = useShowController(props);

  return (
    <Show {...props} actions={<OnlyDeleteShowActions />}>
      <TabbedShowLayout>
        <Tab label='Basic info'>
          <TextField source='id' />
          <TextField source='status' />
          <BooleanField source='active' />
          <SimpleDateField source='created' />
          <SimpleDateField source='updated' />
          <ReferenceField
            source='thumbnailImageFileId'
            reference='Images'
            link='show'
          >
            <FCImageField source='id' />
          </ReferenceField>
          {record != null && record.status === VideoFileStatus.Processed && (
            <FCVideoField source='id' label='Video' />
          )}
        </Tab>
        <Tab label='Amazon s3 info'>
          <TextField source='fileName' />
          <TextField source='s3Bucket' />
          <TextField source='s3KeyRaw' />
          <TextField source='contentTypeRaw' />
          <TextField source='processedS3KeyRaw' />
          <TextField source='processedContentTypeRaw' />
          <TextField source='transcodeJobId' />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const VideoCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <FileInput source='file' accept='video/*'>
        <FileField source='src' title='title' />
      </FileInput>
    </SimpleForm>
  </Create>
);
