import React from 'react';
import {
  BooleanField,
  Datagrid,
  Filter,
  List,
  NumberInput,
  TextField,
} from 'react-admin';

import { CustomPagination, SimpleDateField } from './';

export const AudioFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='Id' source='id' />
  </Filter>
);

export const AudioList = (props: any) => (
  <List
    {...props}
    pagination={<CustomPagination />}
    title={'Audio files'}
    filters={<AudioFilter />}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='s3Bucket' />
      <TextField source='s3KeyRaw' />
      <TextField source='contentTypeRaw' />
      <BooleanField source='active' />
      <SimpleDateField source='created' />
      <SimpleDateField source='updated' />
    </Datagrid>
  </List>
);
