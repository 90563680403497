import React from 'react';
import {
  BooleanField,
  BooleanInput,
  ChipField,
  Datagrid,
  DateTimeInput,
  Edit,
  FormTab,
  List,
  ReferenceField,
  Show,
  Tab,
  TabbedForm,
  TabbedShowLayout,
  TextField,
  NumberInput,
  TextInput,
  Filter,
} from 'react-admin';

import { BasicShowActions } from './BasicShowActions';
import {
  CustomPagination,
  FCImageField,
  FCImageInput,
  SimpleDateField,
} from './';

export const FamilyGroupFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='Id' source='id' />
    <NumberInput label='Owner user id' source='ownerUserId' />
  </Filter>
);

export const FamilyGroupList = (props: any) => (
  <List
    {...props}
    pagination={<CustomPagination />}
    title={'Family groups'}
    filters={<FamilyGroupFilter />}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='urlSuffix' />
      <TextField source='displayName' />
      <TextField source='planLevel' />
      <BooleanField source='active' />
      <ReferenceField source='ownerUserId' reference='Users' link='show'>
        <ChipField source='id' />
      </ReferenceField>
      <ReferenceField source='coverImageId' reference='Images' link='show'>
        <ChipField source='id' />
      </ReferenceField>
      <ReferenceField
        source='rootUserProfileNodeId'
        reference='UserProfiles'
        link='show'
      >
        <ChipField source='id' />
      </ReferenceField>
      <SimpleDateField source='createdTimestamp' />
      <TextField source='genericInviteCode' />
    </Datagrid>
  </List>
);

export const FamilyGroupShow = (props: any) => (
  <Show {...props} actions={<BasicShowActions />}>
    <TabbedShowLayout>
      <Tab label='Basic info'>
        <TextField source='id' />
        <TextField source='urlSuffix' />
        <TextField source='displayName' />
        <TextField source='planLevel' />
        <BooleanField source='active' />
        <ReferenceField source='ownerUserId' reference='Users' link='show'>
          <ChipField source='id' />
        </ReferenceField>
        <ReferenceField source='coverImageId' reference='Images' link='show'>
          <FCImageField title='Cover image' source='id' />
        </ReferenceField>
        <ReferenceField
          source='rootUserProfileNodeId'
          reference='UserProfiles'
          link='show'
        >
          <ChipField source='id' />
        </ReferenceField>
        <SimpleDateField source='createdTimestamp' />
        <TextField source='genericInviteCode' />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const FamilyGroupEdit = (props: any) => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label='Basic info'>
        <TextInput source='id' disabled />
        <TextInput source='urlSuffix' />
        <TextInput source='displayName' />
        <TextInput source='planLevel' />
        <BooleanInput source='active' disabled />
        <DateTimeInput source='createdTimestamp' disabled />
        <TextInput source='ownerUserId' />
        <FCImageInput source='coverImageId' title='Cover image' />
        <TextInput source='rootUserProfileNodeId' disabled />
        <TextInput source='genericInviteCode' />
      </FormTab>
    </TabbedForm>
  </Edit>
);
