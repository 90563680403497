import { getAuthTokenSafe } from "./AuthUtils";
import { superuserModulePrivateRpc } from "./Rpc";

export class ResourceAccessTokenUtil{
  private isStarted: boolean = false;
  private async getResourceAccessToken(){
    try{
      const token: string = getAuthTokenSafe(); 
      if(token.length > 0){
        await superuserModulePrivateRpc.createSuperuserResourceAccessToken(token);
      }
    } catch(e){
      console.log(`Error occurred during resource access token refreshing ${e}.`)
    }
  }

  public StartRefreshingResourceAccessToken(){
    if(this.isStarted === true){
      return;
    }
    this.isStarted = true;
    this.getResourceAccessToken();
    setInterval(()=>{
      this.getResourceAccessToken();
    }, 1000*60*45);
  }
}

export const ResourceAccessTokenUtilInstance = new ResourceAccessTokenUtil();