import React, { useCallback, useEffect, useState } from 'react';
import { ImageField, ImageInput } from 'react-admin';
import styled from 'styled-components';

import { FCImageField } from '.';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const FCImageInput = (props: any) => {
  const { source } = props;
  const [inputHasChanged, setInputHasChanged] = useState<boolean>(false);

  return <Wrapper>
    <ImageInput label='' source={source} onChange={()=>{setInputHasChanged(true)}}>
      { 
        inputHasChanged === false ? <FCImageField></FCImageField> : <ImageField></ImageField>
      }
    </ImageInput>
  </Wrapper>
}

FCImageInput.defaultProps = {
  addLabel: true,
};