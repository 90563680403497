import { HttpError } from 'react-admin';

export class BaseProvider {
  public static getList(params: any): Promise<any> {
    return Promise.reject(new HttpError(
      'Not implemented',
      501,
      'Not implemented'
    ));
  }
  public static getOne(params: any): Promise<any> {
    return Promise.reject(new HttpError(
      'Not implemented',
      501,
      'Not implemented'
    ));
  }
  public static getMany(params: any): Promise<any> {
    return Promise.reject(new HttpError(
      'Not implemented',
      501,
      'Not implemented'
    ));
  }
  public static getManyReference(params: any): Promise<any> {
    return Promise.reject(new HttpError(
      'Not implemented',
      501,
      'Not implemented'
    ));
  }
  public static create(params: any): Promise<any> {
    return Promise.reject(new HttpError(
      'Not implemented',
      501,
      'Not implemented'
    ));
  }
  public static update(params: any): Promise<any> {
    return Promise.reject(new HttpError(
      'Not implemented',
      501,
      'Not implemented'
    ));
  }
  public static updateMany(params: any): Promise<any> {
    return Promise.reject(new HttpError(
      'Not implemented',
      501,
      'Not implemented'
    ));
  }
  public static delete(params: any): Promise<any> {
    return Promise.reject(new HttpError(
      'Not implemented',
      501,
      'Not implemented'
    ));
  }
  public static deleteMany(params: any): Promise<any> {
    return Promise.reject(new HttpError(
      'Not implemented',
      501,
      'Not implemented'
    ));
  }
}