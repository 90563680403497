import React from 'react';
import { Filter, NumberInput, Datagrid, List, TextField, ReferenceField, ChipField, Record } from 'react-admin';

export const DetailedRsvpReportWithCountsFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label="Reunion Id" source="reunionId" />
    <NumberInput label="Family Group Id" source="familyGroupId" />
  </Filter>
);

const summaryRowStyle = (record: Record, index: number) => ({
  backgroundColor: record.id === "summary" ? 'whitesmoke' : 'white',
});

const DynamicDatagridFields = (props: any) => {
  const activityColumns = props.total || [];

  return <Datagrid rowStyle={summaryRowStyle}>
    <TextField source="firstName" sortable={false} />
    <TextField source="lastName" sortable={false} />
    <TextField source="middleName" sortable={false} />
    <TextField source="suffixName" sortable={false} />
    <ReferenceField source="reunionId" label="Reunion Id" reference="Reunions" link="show" sortable={false}>
      <ChipField source="id" />
    </ReferenceField>
    <TextField source="reunionDisplayName" label="Reunion name" sortable={false}/>
    <ReferenceField source="familyGroupId" label="Family Group Id" reference="FamilyGroups" link="show" sortable={false}>
      <ChipField source="id" />
    </ReferenceField>
    <TextField source="familyGroupDisplayName" label="Family Group name" sortable={false}/>
    <TextField source="response" label="Reunion response" sortable={false}/>
    {activityColumns.map((column: string) => <TextField source={`${column}`} sortable={false}/>)}
  </Datagrid>;
};

export const DetailedRsvpReportWithCountsList = (props: any) => (
  <List 
    {...props} 
    bulkActionButtons={false}
    pagination={false} 
    title={'Detailed rsvp report with counts'}
    filters={<DetailedRsvpReportWithCountsFilter />} >
    <DynamicDatagridFields {...props}/>
  </List>
);