import React from 'react';
import {
  Datagrid,
  Filter,
  NumberInput,
  TextInput,
  List,
  TextField,
} from 'react-admin';

import { CustomPagination } from './elements';

export const NotificationFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='Id' source='id' />
    <TextInput label='Status' source='status' />
    <TextInput label='Type' source='type' />
    <TextInput label='Mailgun status' source='mailgunNotificationStatus' />
    <TextInput label='Twilio status' source='twilioNotificationStatus' />
    <TextInput label='Firebase status' source='firebaseNotificationStatus' />
  </Filter>
);

export const NotificationList = (props: any) => (
  <List
    {...props}
    pagination={<CustomPagination />}
    title={'Notifications'}
    bulkActionButtons={false}
    filters={<NotificationFilter />}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='title' />
      <TextField source='recipient' />
      <TextField source='type' />
      <TextField source='status' />
      <TextField source='externalId' />
      <TextField source='mailgunEmailDispatchStatus' />
      <TextField source='twilioSmsDispatchStatus' />
      <TextField source='firebasePushDispatchStatus' />
    </Datagrid>
  </List>
);
