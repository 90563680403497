import React from 'react';
import {
  ArrayField,
  ChipField,
  Datagrid,
  Filter,
  List,
  NumberInput,
  ReferenceField,
  Show,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';

import {
  BasicShowActions,
  CustomPagination,
  FCImageField,
  SimpleDateField,
} from '.';

export const AlbumFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label='Id' source='id' />
  </Filter>
);

export const AlbumList = (props: any) => (
  <List
    {...props}
    pagination={<CustomPagination />}
    title={'Albums'}
    filters={<AlbumFilter />}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='title' />
      <ReferenceField
        source='familyGroupId'
        reference='FamilyGroups'
        link='show'
      >
        <ChipField source='id' />
      </ReferenceField>
      <ReferenceField
        source='creatorUserProfileId'
        reference='UserProfiles'
        link='show'
      >
        <ChipField source='id' />
      </ReferenceField>
      <ReferenceField source='reunionId' reference='Reunions' link='show'>
        <ChipField source='id' />
      </ReferenceField>
      <SimpleDateField source='created' />
      <SimpleDateField source='updated' />
    </Datagrid>
  </List>
);

export const AlbumShow = (props: any) => (
  <Show {...props} actions={<BasicShowActions />}>
    <TabbedShowLayout>
      <Tab label='Basic info'>
        <TextField source='id' />
        <TextField source='title' />
        <ReferenceField
          source='familyGroupId'
          reference='FamilyGroups'
          link='show'
        >
          <ChipField source='id' />
        </ReferenceField>
        <ReferenceField
          source='creatorUserProfileId'
          reference='UserProfiles'
          link='show'
        >
          <ChipField source='id' />
        </ReferenceField>
        <ReferenceField source='reunionId' reference='Reunions' link='show'>
          <ChipField source='id' />
        </ReferenceField>
        <SimpleDateField source='created' />
        <SimpleDateField source='updated' />
      </Tab>
      <Tab label='Album images'>
        <ArrayField source='albumImages'>
          <Datagrid>
            <ReferenceField source='id' reference='AlbumImages' link='show'>
              <ChipField source='id' />
            </ReferenceField>
            <ReferenceField source='imageFileId' reference='Images' link='show'>
              <FCImageField source='id' title='Image' />
            </ReferenceField>
            <TextField source='title' />
            <TextField source='description' />
            <ArrayField source='taggedUserProfiles'>
              <SingleFieldList>
                <ReferenceField
                  source='id'
                  reference='UserProfiles'
                  link='show'
                >
                  <ChipField source='id' />
                </ReferenceField>
              </SingleFieldList>
            </ArrayField>
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
