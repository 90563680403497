export class FileUtils {
  public static async FileToArrayBuffer(file: File): Promise<ArrayBuffer>{
    try{
      const result: ArrayBuffer = await this.BlobToArrayBuffer(file);
      return result;
    } catch(e){
      throw new Error(`Can't read file ${file.name} as array buffer for some reason: ${e}.`);
    }
  }

  public static async FileToDateUrl(file: File): Promise<string>{
    try{
      const result: string = await this.BlobToDateUrl(file);
      return result;
    } catch(e){
      throw new Error(`Can't read file ${file.name} as base64 for some reason: ${e}.`);
    }
  }

  public static BlobToArrayBuffer(file: Blob): Promise<ArrayBuffer>{
    return new Promise((resolve:(value: ArrayBuffer) => void, reject: (error: string | DOMException | null) => void)=>{
      const fileReader: FileReader = new FileReader();
      fileReader.onload = (event: ProgressEvent<FileReader>) => {
        if(event != null && event.target != null && event.target.result != null ){
          resolve(event.target.result as ArrayBuffer);
        } else {
          reject(`Can't convert blob to array buffer for some reason.`);
        }
      };
      fileReader.onerror = () =>{
        reject(fileReader.error);
      }
      fileReader.readAsArrayBuffer(file);
    })
  }

  public static BlobToDateUrl(file: Blob): Promise<string>{
    return new Promise((resolve:(value: string) => void, reject: (error: string | DOMException | null) => void)=>{
      const fileReader: FileReader = new FileReader();
      fileReader.onload = (event: ProgressEvent<FileReader>) => {
        if(event != null && event.target != null && event.target.result != null ){
          resolve(event.target.result as string);
        } else {
          reject(`Can't convert blob to base64 for some reason.`);
        }
      };
      fileReader.onerror = () =>{
        reject(fileReader.error);
      }
      fileReader.readAsDataURL(file);
    })
  }

  public static ArrayBufferToBase64(file: ArrayBuffer): string{
    let binary: string = '';
    let bytes: Uint8Array = new Uint8Array(file);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    const base64: string = window.btoa(binary);
    return base64;
  }
}