import { HttpError } from 'react-admin';

import { reunionReportSuperuserModulePrivateRpc, getAuthTokenSafe } from '../utils';
import { BaseProvider } from './BaseProvider';

export class DetailedRsvpReportWithCountsProvider extends BaseProvider {
  public static getList(params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return reunionReportSuperuserModulePrivateRpc.getDetailedRsvpWithCountsReport(
        getAuthTokenSafe(),
        undefined,
        params.filter.reunionId,
        params.filter.familyGroupId
      ).then(response => {
        resolve({
          data: response.data,
          total: response.activityColumns //using total to pass dynamic activities columns as it is not being used for pagination purposes
        });
      })
        .catch(error => {
          reject(
            new HttpError(
              error.message || 'Unexpected error',
              error.httpCode,
              error
            )
          );
        })
    });
  }

}